import { Button } from "@contentful/f36-button";
import { PreviewIcon } from "@contentful/f36-icons";
import * as React from "react";
import { useState } from "react";

import templatesPlaceholder from "../../../assets/images/templatesImg.png";
import { useApiService } from "helpers/api-service";

type TemplatesPageCardProps = {
	appData: {
		_id: string;
		name: string;
		updatedAt: string;
		createdAt: string;
		isDeleted: boolean;
	};
	setIsTemplateModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
	setIsCreateAppModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
	setTemplateID: React.Dispatch<React.SetStateAction<string>>;
};

const TemplatesPageCard: React.FunctionComponent<TemplatesPageCardProps> = ({
	appData,
	setIsTemplateModalOpen,
	setIsCreateAppModalOpen,
	setTemplateID,
}) => {
	const [isMouseOver, setIsMouseOver] = useState(false);

	return (
		<div
			className="templateContainer mainTemps"
			onMouseEnter={() => setIsMouseOver(true)}
			onMouseLeave={() => setIsMouseOver(false)}
		>
			<div className="imgWrapper">
				<img className="main" src={templatesPlaceholder} alt="" />
				{isMouseOver && (
					<div className="hoverDetails">
						<p className="templateTitle">Template name</p>
						<p className="templateDetails">
							Lorem ipsum dolor sit amet consectetur. In dolor mauris
							pellentesque sed. Semper malesuada faucibus posuere eu pharetra
							enim.{" "}
						</p>
						<Button
							startIcon={<PreviewIcon />}
							// size="small"
							variant="secondary"
							onClick={() => {
								setIsTemplateModalOpen(true);
								setTemplateID(appData?._id);
							}}
						>
							Preview
						</Button>
						<Button
							variant="primary"
							onClick={() => {
								setIsCreateAppModalOpen(true);
								setTemplateID(appData?._id);
							}}
						>
							Use Template
						</Button>
					</div>
				)}
			</div>
			<div className="cardDetails">
				<div className="leftDeets">
					<p className="appName">{appData?.name}</p>
					<p className="lastViewed">Uno</p>
				</div>
			</div>
		</div>
	);
};

export default TemplatesPageCard;
