import { AxiosError } from "axios";
import { Flex } from "@contentful/f36-components";
import { Button } from "@contentful/f36-button";
import { PlusIcon } from "@contentful/f36-icons";
import { TextInput } from "@contentful/f36-components";
import { Space, Tag, Modal, Spin, Input, Pagination } from "antd";
import type { PaginationProps } from "antd";
import { CloseModalIcon } from "assets/icons/icons";
import Sidebar from "components/sidebar/Sidebar";
import TemplatesPageCard from "components/template/templatesPageCard/TemplatesPageCard";
import Topbar from "components/topbar/Topbar";
import { useApiService } from "helpers/api-service";
import React, { useEffect, useState } from "react";
import { AppDataProps } from "../appsPage/AppsPage";
import "./templates.scss";
import { TemplateUnoSVG, SearchIcon } from "assets/icons/icons";
import { useNavigate } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

import TemplateFeatureCard from "../../../components/template/templatesFeatureCard/templateFeatureCard";
import { useMessageService } from "helpers/toast";
import { useUserService } from "helpers/user-service";
import SortFilter from "components/filters/SortFilter";
import {
	ALPHABETICAL_SORT,
	DATE_CREATED_SORT,
	LAST_VIEWED_SORT,
	NEWEST_ORDER_SORT,
} from "utils/constants";

interface TemplatesProps {}

interface Error {
	message: string;
	statusCode: number;
}

const Templates: React.FunctionComponent<TemplatesProps> = () => {
	const [allTemplates, setAllTemplates] = useState<AppDataProps[]>([]);
	const [isTemplatesPageLoading, setIsTemplatesPageLoading] =
		useState<boolean>(true);
	const [isTemplateModalOpen, setIsTemplateModalOpen] =
		useState<boolean>(false);
	const [isCreateAppModalOpen, setIsCreateAppModalOpen] =
		useState<boolean>(false);
	const [isCreateTemplateModalOpen, setIsCreateTemplateModalOpen] =
		useState<boolean>(false);
	const [templateID, setTemplateID] = useState<string>("");
	const [newAppName, setNewAppName] = useState<string>("");
	const [newTemplateName, setNewTemplateName] = useState<string>("");
	const [searchString, setSearchString] = useState<string>("");
	const [isSortOpen, setIsSortOpen] = useState(false);
	const [templatesSort, setTemplatesSort] = useState<string>(ALPHABETICAL_SORT);
	const [orderSort, setOrderSort] = useState<number>(NEWEST_ORDER_SORT);
	const [sortFetchString, setSortFetchString] = useState<string>("name");
	const [sortOrderFetchNumber, setSortOrderFetchNumber] = useState<number>(1);
	const [currentPage, setCurrentPage] = useState<number>(1);

	const client = useApiService();
	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const { messageApi } = useMessageService();
	const { user } = useUserService();

	// const fetchAllTemplates = async () => {
	// 	const response = await client.get(`/templates`);
	// 	setAllTemplates(response.data.results);
	// 	setIsTemplatesPageLoading(false);
	// };

	const { data: templates, status: templatesStatus } = useQuery({
		queryKey: [
			"templates",
			searchString,
			sortFetchString,
			sortOrderFetchNumber,
			currentPage,
		],
		queryFn: async () => {
			const response = await client.get(
				`/templates/?${
					searchString && `search=${searchString}`
				}&sortBy[${sortFetchString}]=${sortOrderFetchNumber}&page=${currentPage}`
			);
			return response.data;
		},
		onSuccess(data) {
			console.log(data);
		},
	});

	const handleSort = async (sortOrder: number, sortBy: string) => {
		setSortOrderFetchNumber(sortOrder);

		switch (sortBy) {
			case LAST_VIEWED_SORT:
				setSortFetchString("lastViewed");
				break;
			case DATE_CREATED_SORT:
				setSortFetchString("createdAt");
				break;
			default:
				setSortFetchString("name");
				break;
		}
	};

	const { data: templateData, status: templateStatus } = useQuery({
		queryKey: ["templateData", templateID],
		queryFn: async () => {
			const response = await client.get(`/templates/${templateID}`);
			return response.data;
		},
	});

	const onChange: PaginationProps["onChange"] = (pageNumber) => {
		console.log("Page: ", pageNumber);
		setCurrentPage(pageNumber);
	};

	const CreateAppMutation = useMutation({
		mutationFn: async (data: object) => {
			const response = await client.post("/apps", data);
			return response;
		},

		onSuccess(data, variables, context) {
			setIsCreateAppModalOpen(false);
			setIsTemplateModalOpen(false);
			setNewAppName("");
			navigate(`/app/${data?.data._id}`);
		},
		onError(error: AxiosError<Error>) {
			messageApi.open({
				type: "error",
				content: error.response.data.message,
			});
		},
	});

	const CreateTemplateMutation = useMutation({
		mutationFn: async (data: object) => {
			const response = await client.post("/templates", data);
			return response;
		},
		onSuccess(data, variables, context) {
			setIsCreateTemplateModalOpen(false);
			setIsTemplateModalOpen(false);
			setNewTemplateName("");
			messageApi.open({
				type: "success",
				content: data?.statusText,
			});
			queryClient.invalidateQueries({ queryKey: ["templates"], exact: true });
		},
		onError(error: AxiosError<Error>) {
			messageApi.open({
				type: "error",
				content: error.response.data.message,
			});
		},
	});

	const Tags = [
		{ text: "Carousel" },
		{ text: "Input fields" },
		{ text: "Forms connected to API" },
		{ text: "Multiple requests" },
		{ text: "Modals" },
		{ text: "Remote actions" },
	];

	const panels = [
		{
			title: "Animations",
			text: "All devices optimised for screens with high DPI",
		},
		{
			title: "Text styles",
			text: "All devices optimised for screens with high DPI",
		},
		{
			title: "Scroll effects",
			text: "All devices optimised for screens with high DPI",
		},
	];

	return (
		<Flex>
			<Sidebar />
			<main>
				<Topbar />
				<div className="templateLayout">
					<div className="topLayout">
						<h3 className="pageName">Templates</h3>

						<div className="searchNcreate">
							<Input
								prefix={<SearchIcon />}
								onChange={(e) => setSearchString(e.target.value)}
								placeholder="Search"
								allowClear
							/>
							{user.role === "ADMIN" && (
								<Button
									className="createNew"
									startIcon={<PlusIcon />}
									variant="primary"
									onClick={() => setIsCreateTemplateModalOpen(true)}
								>
									Create new
								</Button>
							)}
						</div>
					</div>
					<div className="sortBox">
						<SortFilter
							isSortOpen={isSortOpen}
							setIsSortOpen={setIsSortOpen}
							appsSort={templatesSort}
							setAppsSort={setTemplatesSort}
							orderSort={orderSort}
							setOrderSort={setOrderSort}
							handleSort={handleSort}
						/>
					</div>
					{templatesStatus === "loading" ? (
						<div className="tempsSpinBox">
							<Spin />
						</div>
					) : templatesStatus === "success" ? (
						<div className="gridTemplates">
							{templates?.results?.map((appDetails: any) => (
								<TemplatesPageCard
									key={appDetails._id}
									appData={appDetails}
									setIsTemplateModalOpen={setIsTemplateModalOpen}
									setIsCreateAppModalOpen={setIsCreateAppModalOpen}
									setTemplateID={setTemplateID}
								/>
							))}
						</div>
					) : null}
					<div className="pagination">
						<Pagination
							total={templates?.totalResults}
							current={currentPage}
							onChange={onChange}
						/>
					</div>
				</div>
			</main>
			<Modal
				centered
				open={isTemplateModalOpen}
				onOk={() => setIsTemplateModalOpen(false)}
				onCancel={() => setIsTemplateModalOpen(false)}
				closable={false}
				className="createNew"
				width={"960px"}
				footer={null}
			>
				{templateStatus === "loading" ? (
					<div className="tempsSpinBox">
						<Spin />
					</div>
				) : templateStatus === "success" ? (
					<>
						<div className="modalTitle">
							<h3 className="templateName">{templateData?.name}</h3>
						</div>
						<div className="modalContent">
							<div className="mediaContainer">
								<TemplateUnoSVG />
							</div>
							<div className="descriptionCont">
								<div className="descriptionText">
									<p>
										Blicker is an attractive and stylish Webflow website
										template designed for real estate. It has strong layouts
										that give your real estate projects a professional and
										creative look. This template is perfect for real estate
										agencies, apartments, architecture, brokers, rental
										businesses, property development and property management.{" "}
										<br></br>
										<br />
										Blicker is an attractive and stylish Webflow website
										template designed for real estate. It has strong layouts
										that give your real estate projects a professional and
										creative look. This template is perfect for real estate
										agencies, apartments, architecture, brokers, rental
										businesses, property development and property management.
									</p>
								</div>
								<div className="featsAndFxns">
									<Button
										size="small"
										isFullWidth
										onClick={() => setIsCreateAppModalOpen(true)}
										variant="primary"
									>
										Use Template
									</Button>
									<h3>Functions</h3>
									<Space size={[0, 8]} wrap>
										{Tags.map((tag) => (
											<Tag color="#F2F9FF">
												<p>{tag.text}</p>
											</Tag>
										))}
									</Space>
									<hr />
									<h3>Features</h3>
									<div className="feats">
										{panels.map((panel) => (
											<TemplateFeatureCard featInfo={panel} />
										))}
									</div>
								</div>
							</div>
						</div>
					</>
				) : null}
			</Modal>
			<Modal
				centered
				open={isCreateAppModalOpen}
				onOk={() => setIsCreateAppModalOpen(false)}
				onCancel={() => setIsCreateAppModalOpen(false)}
				closable={false}
				className="createApp"
				width={"280px"}
				footer={null}
			>
				<>
					<div className="title">
						<h3 className="titleName">App Name</h3>
					</div>
					<div className="input">
						<TextInput
							onChange={(e) => setNewAppName(e.target.value)}
							value={newAppName}
							placeholder="Enter app name"
						/>
						<div className="buttonBox">
							<Button
								variant="primary"
								onClick={() =>
									CreateAppMutation.mutate({
										name: newAppName,
										template: templateID,
									})
								}
								isDisabled={newAppName.length < 3}
							>
								Create
							</Button>
						</div>
					</div>
				</>
			</Modal>
			<Modal
				centered
				open={isCreateTemplateModalOpen}
				onOk={() => setIsCreateTemplateModalOpen(false)}
				onCancel={() => setIsCreateTemplateModalOpen(false)}
				closable={false}
				className="createApp"
				width={"280px"}
				footer={null}
			>
				<>
					<div className="title">
						<h3 className="titleName">Template Name</h3>
					</div>
					<div className="input">
						<TextInput
							onChange={(e) => setNewTemplateName(e.target.value)}
							value={newTemplateName}
							placeholder="Enter template name"
						/>
						<div className="buttonBox">
							<Button
								variant="primary"
								onClick={() =>
									CreateTemplateMutation.mutate({
										name: newTemplateName,
									})
								}
								isDisabled={newTemplateName.length < 3}
							>
								Create
							</Button>
						</div>
					</div>
				</>
			</Modal>
		</Flex>
	);
};

export default Templates;
