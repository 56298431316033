import React from "react";
import { Route, Navigate, Outlet } from "react-router-dom";

import { LOGIN_ROUTE } from "../../utils/routes";
import { useUserService } from "../../helpers/user-service";
import { useAuthService } from "../../helpers/auth-service";

interface PropType {
	children?: React.ReactElement | null;
	isAllowed?: boolean;
}

const ProtectedRoute: React.FC<PropType> = ({ children, isAllowed }) => {
	const { token } = useAuthService();
	const { user } = useUserService();

	return user ? (
		<>
			{children}
			<Outlet />
		</>
	) : (
		<Navigate to={LOGIN_ROUTE} replace /> //Go back to login if not logged in
	);
};

export default ProtectedRoute;
