import React, { useEffect, useState } from "react";
import "./apiCard.scss";
import { HorizontalDotsMenuIcon, SuccessCheckIcon } from "assets/icons/icons";
import ApiBadge from "components/badge/ApiBadge";
import {
  APICall,
  GroupCall,
} from "pages/protected/apiCollections/APICollections";
import { Button, Popover } from "@contentful/f36-components";
import Divider from "components/divider/Divider";
import { Input, message, Modal } from "antd";
import { useApiService } from "helpers/api-service";
import { useMutation, useQueryClient } from "@tanstack/react-query";

type ApiCallNameCardProps = {
  apiCallList: APICall[];
  setSelectedApiCall: React.Dispatch<React.SetStateAction<APICall>>;
  apiCallObject: APICall;
  setSelectedGroup: React.Dispatch<React.SetStateAction<GroupCall>>;
  group: GroupCall;
  refetchCalls: () => any;
  resetResponse: () => void;
};

const ApiCallNameCard: React.FunctionComponent<ApiCallNameCardProps> = ({
  apiCallList,
  setSelectedApiCall,
  apiCallObject,
  setSelectedGroup,
  group,
  refetchCalls,
  resetResponse,
}) => {
  const [isMenuPopOverOpen, setIsMenuPopOverOpen] = useState<boolean>(false);
  const [showPopOverIcon, setShowPopOverIcon] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [isRenameModalOpen, setIsRenameModalOpen] = useState<boolean>(false);
  const [isDeleteApiCallLoading, setIsDeleteApiCallLoading] =
    useState<boolean>(false);
  const client = useApiService();
  const [isRenameCallLoading, setIsRenameCallLoading] =
    useState<boolean>(false);
  const [renameCallText, setRenameCallText] = useState<string>(
    apiCallObject.requestName
  );
  const [messageApi, contextHolder] = message.useMessage();
  const queryClient = useQueryClient();

  const deleteApiCall = async () => {
    const response = await client.delete(`/apicalls/${apiCallObject._id}`);
    return response.data;
  };

  const renameCallFn = async (callName: { requestName: string }) => {
    const response = await client.patch(
      `/apicalls/${apiCallObject._id}`,
      callName
    );
    return response.data;
  };

  const RenameCallMutation = useMutation({
    mutationFn: renameCallFn,
    onSuccess(data, variables, context) {
      console.log(data);
      setIsRenameModalOpen(false);
      setIsRenameCallLoading(false);
      setRenameCallText("");
      refetchCalls();
      messageApi.open({
        type: "success",
        content: "API call renamed",
        icon: <SuccessCheckIcon />,
      });
      setSelectedApiCall(data);
      apiCallList.map((api) => {
        if (api._id === data._id) {
          api.class = "callCont selectedCall";
          api.showMenuIcon = true;
        }
      });
    },
    onError(error, variables, context) {
      console.log(error);
      setIsRenameCallLoading(false);
    },
  });

  const renameCall = () => {
    setIsRenameCallLoading(true);
    RenameCallMutation.mutate({ requestName: renameCallText });
  };

  const handleApiCardClick = () => {
    setSelectedGroup(group);
    // setResponse(false);
    resetResponse();
    apiCallList.map((api) => {
      if (api._id === apiCallObject._id) {
        setSelectedApiCall(api);
        api.class = "callCont selectedCall";
        api.showMenuIcon = true;
      } else {
        api.class = "callCont";
        api.showMenuIcon = false;
      }
    });
  };

  useEffect(() => {
    setRenameCallText(apiCallObject.requestName);
  }, [isRenameModalOpen]);

  const DeleteModalFooter = () => {
    return (
      <div className="renameFooter">
        <button onClick={() => setIsDeleteModalOpen(false)} className="cancel">
          Cancel
        </button>
        <Button
          isDisabled={isDeleteApiCallLoading}
          isLoading={isDeleteApiCallLoading}
          className="delete"
          onClick={() => {
            setIsDeleteApiCallLoading(true);
            deleteApiCall().then(() => {
              setIsDeleteModalOpen(false);
              setIsDeleteApiCallLoading(false);
              refetchCalls();
              setSelectedGroup({
                _id: "",
                name: "",
                apiCalls: [],
              });
            });
          }}
        >
          Delete
        </Button>
      </div>
    );
  };

  const RenameModalFooter = () => {
    return (
      <div className="renameFooter">
        <button onClick={() => setIsRenameModalOpen(false)} className="cancel">
          Cancel
        </button>
        <Button
          isLoading={isRenameCallLoading}
          isDisabled={isRenameCallLoading}
          onClick={renameCall}
          className="update"
        >
          Rename
        </Button>
      </div>
    );
  };

  return (
    <div
      className={apiCallObject.class}
      onMouseEnter={() => setShowPopOverIcon(true)}
      onMouseLeave={() => setShowPopOverIcon(false)}
      onClick={handleApiCardClick}
    >
      {contextHolder}
      <div className="left">
        <div className="padBadge">
          <ApiBadge type={apiCallObject.methodType} />
        </div>
        <p className="callName">{apiCallObject.requestName}</p>
      </div>
      <div className="right">
        <Popover
          isOpen={isMenuPopOverOpen}
          id="gridPopover"
          onClose={() => setIsMenuPopOverOpen(false)}
        >
          <Popover.Trigger>
            <button
              className={isMenuPopOverOpen ? "actv" : ""}
              onClick={() => setIsMenuPopOverOpen(!isMenuPopOverOpen)}
            >
              {showPopOverIcon || apiCallObject.showMenuIcon ? (
                <HorizontalDotsMenuIcon />
              ) : (
                <></>
              )}
            </button>
          </Popover.Trigger>
          <Popover.Content>
            <div className="dropWrapper">
              <button className="menuDrop">Save</button>
              <button className="menuDrop">Duplicate</button>
              <Divider />
              <button
                onClick={() => setIsRenameModalOpen(true)}
                className="menuDrop"
              >
                Rename
              </button>
              <button
                onClick={() => setIsDeleteModalOpen(true)}
                className="menuDrop"
              >
                Delete
              </button>
            </div>
          </Popover.Content>
        </Popover>
      </div>
      <Modal
        title="Delete api call"
        centered
        open={isDeleteModalOpen}
        onOk={() => setIsDeleteModalOpen(false)}
        onCancel={() => setIsDeleteModalOpen(false)}
        className="deleteModal"
        closable={false}
        width="320px"
        footer={<DeleteModalFooter />}
      >
        <p>Are you sure you want to delete this api call?</p>
      </Modal>
      <Modal
        title="Rename api call"
        centered
        open={isRenameModalOpen}
        onOk={() => setIsRenameModalOpen(false)}
        onCancel={() => setIsRenameModalOpen(false)}
        className="renameModal"
        closable={false}
        footer={<RenameModalFooter />}
        width="320px"
      >
        <p>Group name</p>
        <Input
          value={renameCallText}
          placeholder="Enter group name"
          onChange={(e) => setRenameCallText(e.target.value)}
        />
      </Modal>
    </div>
  );
};

export default ApiCallNameCard;
