import React, { createContext, useContext, useState } from "react";
import { message } from "antd";
import { MessageInstance } from "antd/es/message/interface";

interface MessageContextProps {
	messageApi: MessageInstance;
}

const MessageContext = createContext<MessageContextProps | null>(null);

interface Provider {
	children: React.ReactElement;
}

export const MessageServiceProvider: React.FC<Provider> = ({ children }) => {
	const [messageApi, contextHolder] = message.useMessage();

	return (
		<MessageContext.Provider value={{ messageApi }}>
			{contextHolder}
			{children}
		</MessageContext.Provider>
	);
};

export function useMessageService() {
	return useContext(MessageContext);
}
