import React from "react";
import "./divider.scss";

type DividerProps = {};

const Divider: React.FunctionComponent<DividerProps> = () => {
	return <div className="divider"></div>;
};

export default Divider;
