import React from "react";
import "./emptyAppsPage.scss";

type EmptyAppsPageProps = {
  handleClick: () => void;
};

const EmptyAppsPage: React.FunctionComponent<EmptyAppsPageProps> = ({
  handleClick,
}) => {
  return (
    <div className="emptyWrapper">
      <p className="no">No apps created</p>
      <p className="sub">Create a new app to get started</p>
      <button onClick={handleClick}>Create new app</button>
    </div>
  );
};

export default EmptyAppsPage;
