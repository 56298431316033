import React, { useMemo, useContext, createContext, ReactNode } from "react";
import Axios, {
	AxiosInstance,
	AxiosResponse,
	AxiosRequestConfig,
	AxiosDefaults,
} from "axios";
import { useAuthService } from "./auth-service";
import { REACT_APP_BASE_API_URL } from "utils/env";

export const ApiServiceContext = createContext<AxiosInstance | null>(null);

interface IProviderProps {
	children: React.ReactElement;
}

interface IAxiosInstance {
	request<T = any, R = AxiosResponse<T>>(
		config: AxiosRequestConfig
	): Promise<R>;
	defaults: AxiosDefaults;
	request<T = any>(config: AxiosRequestConfig): Promise<T>;
	get<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>;
	delete<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>;
	head<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>;
	post<T = any>(
		url: string,
		data?: any,
		config?: AxiosRequestConfig
	): Promise<T>;
	put<T = any>(
		url: string,
		data?: any,
		config?: AxiosRequestConfig
	): Promise<T>;
	patch<T = any>(
		url: string,
		data?: any,
		config?: AxiosRequestConfig
	): Promise<T>;
	(config: AxiosRequestConfig): Promise<any>;
}

export const ApiServiceProvider: React.FC<IProviderProps> = ({ children }) => {
	const { token } = useAuthService();
	const client = useMemo(() => {
		const instance = Axios.create({
			baseURL: REACT_APP_BASE_API_URL,
			headers: {
				"Content-Type": "application/json",
			},
		});

		if (token) {
			instance.defaults.headers.common.Authorization = `Bearer ${token}`;
		}

		return instance;
	}, [token]);

	return (
		<>
			<ApiServiceContext.Provider value={client}>
				{children}
			</ApiServiceContext.Provider>
		</>
	);
};

export function useApiService() {
	return useContext(ApiServiceContext);
}
