export const SIGNUP_ROUTE:string = '/signup'
export const SIGNUP_VERIFICATION_ROUTE: string = '/signup/verify-mail'
export const SIGNUP_VERIFICATION_EMAIL_ROUTE:string = "/verify-mail"
export const LOGIN_ROUTE: string = '/'
export const FORGOT_PASSWORD_ROUTE: string = "/forgot-password"
export const CHECKMAIL_ROUTE: string = "/forgot-password/check-mail"
export const RESET_PASSWORD_ROUTE: string = "/reset-password"
export const RESET_SUCCESS_ROUTE: string = "/reset-password/success"
export const APPS_PAGE_ROUTE: string = "/apps";
export const API_COLLECTIONS_PAGE_ROUTE: string = "/api-collections";
export const TEMPLATES_PAGE_ROUTE: string = "/templates";