import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import { APPS_PAGE_ROUTE, LOGIN_ROUTE } from "../utils/routes";
import { REACT_APP_BASE_API_URL } from "../utils/env";
import { useAuthService } from "./auth-service";
import Spinner from "components/spinner/spinner";

type User = {
	firstname: string;
	lastname: string;
	role: string;
	email: string;
	id: string;
	emailVerified: boolean;
};

interface ContextType {
	user: User | null;
	setUserObj: (token: string) => void;
	clearUserObj: () => void;
}

interface Error {
	message: string;
	statusCode: number;
}

const UserServiceContext = createContext<ContextType | null>(null);

interface IProviderProps {
	children: React.ReactElement;
}

export const UserServiceProvider: React.FC<IProviderProps> = ({ children }) => {
	// const { token, clearToken } = useAuthService();
	// console.log(useAuthService);
	const useAuth = useAuthService();
	const navigate = useNavigate();
	const [loading, setLoading] = useState<boolean>(false);

	const getInitialState = () => {
		const user = sessionStorage.getItem("User");
		return user ? JSON.parse(user) : null;
	};

	const [user, setUser] = useState<User | null>(getInitialState);

	const setUserObj = async (token: string) => {
		setLoading(true);
		try {
			await axios
				.get(`${REACT_APP_BASE_API_URL}/users/me`, {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				})
				.then((response) => {
					setUser(response.data);
					setLoading(false);
					navigate(APPS_PAGE_ROUTE);
				});
		} catch (error) {
			console.error(error);
		}
	};

	const clearUserObj = () => {
		setUser(null);
		useAuth?.clearToken();
		navigate(LOGIN_ROUTE);
	};

	useEffect(() => {
		sessionStorage.setItem("User", JSON.stringify(user));
	}, [user]);

	return (
		<UserServiceContext.Provider value={{ user, setUserObj, clearUserObj }}>
			{loading === true ? <Spinner /> : children}
		</UserServiceContext.Provider>
	);
};

export function useUserService() {
	return useContext(UserServiceContext);
}
