export const ALL_APPS_FILTER = "All apps";
export const MOBILE_APPS_FILTER = "Mobile apps";
export const WEB_APPS_FILTER = "Web apps";
export const ALPHABETICAL_SORT = "Alphabetical";
export const DATE_CREATED_SORT = "Date created";
export const LAST_VIEWED_SORT = "Last viewed";
export const NEWEST_ORDER_SORT = 1;
export const OLDEST_FIRST_SORT = -1;
export const NEWEST_ORDER_SORT_TEXT = "Newest First";
export const OLDEST_FIRST_SORT_TEXT = "Oldest First";
export const NEWEST_ORDER_SORT_TEXT_ALPHABETICAL = "A-Z";
export const OLDEST_FIRST_SORT_TEXT_ALPHABETICAL = "Z-A";
export const GRID_LAYOUT = "Grid";
export const LIST_LAYOUT = "List";
export const ALL_API_FILTER = "All";
export const API_CALL_FILTER = "API call";
export const API_GROUP_FILTER = "API group";
export const CALL_DEFINITION = "Call definition";
export const RESPONSE_AND_TEST = "Response & Test";
