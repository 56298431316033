import React from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";

import { APPS_PAGE_ROUTE } from "utils/routes";
import { useUserService } from "helpers/user-service";
import { useAuthService } from "helpers/auth-service";

interface PropType {
	children?: React.ReactElement;
}

const GeneralRoute: React.FC<PropType> = ({ children }) => {
	const { user } = useUserService();
	const { token } = useAuthService();
	const navigate = useNavigate();

	return !user ? (
		<>
			{children}
			<Outlet />
		</>
	) : (
		<Navigate to={APPS_PAGE_ROUTE} replace /> //Go to protected route if logged in
	);
};

export default GeneralRoute;
