import { Button, Popover } from "@contentful/f36-components";
import { ArrowDownIcon } from "assets/icons/icons";
import React from "react";
import {
	ALPHABETICAL_SORT,
	DATE_CREATED_SORT,
	LAST_VIEWED_SORT,
	NEWEST_ORDER_SORT,
	NEWEST_ORDER_SORT_TEXT,
	NEWEST_ORDER_SORT_TEXT_ALPHABETICAL,
	OLDEST_FIRST_SORT,
	OLDEST_FIRST_SORT_TEXT,
	OLDEST_FIRST_SORT_TEXT_ALPHABETICAL,
} from "utils/constants";
import "./filters.scss";
import { DoneIcon } from "@contentful/f36-icons";
import Divider from "components/divider/Divider";

type SortFilterProps = {
	isSortOpen: boolean;
	setIsSortOpen: React.Dispatch<React.SetStateAction<boolean>>;
	setAppsSort: React.Dispatch<React.SetStateAction<string>>;
	appsSort: string;
	orderSort: number;
	setOrderSort: React.Dispatch<React.SetStateAction<number>>;
	handleSort?: (sortOrder: number, sortBy: string) => Promise<void>;
};

const SortFilter: React.FunctionComponent<SortFilterProps> = ({
	isSortOpen,
	setIsSortOpen,
	appsSort,
	setAppsSort,
	orderSort,
	setOrderSort,
	handleSort,
}) => {
	return (
		<div className="sort">
			<span className="filtName">Sort:</span>
			<Popover
				id="sortPop"
				isOpen={isSortOpen}
				onClose={() => setIsSortOpen(false)}
			>
				<Popover.Trigger>
					<button
						className="filterApps"
						onClick={() => setIsSortOpen(!isSortOpen)}
					>
						<span>{appsSort}</span>
						<ArrowDownIcon />
					</button>
				</Popover.Trigger>
				<Popover.Content>
					<span className="menuHead">Sort by</span>
					<Button
						onClick={() => {
							setAppsSort(ALPHABETICAL_SORT);
							setIsSortOpen(false);
							handleSort(orderSort, ALPHABETICAL_SORT);
						}}
						className={appsSort === ALPHABETICAL_SORT ? "checkedActive" : ""}
						startIcon={<DoneIcon />}
					>
						{ALPHABETICAL_SORT}
					</Button>
					<Button
						onClick={() => {
							setAppsSort(DATE_CREATED_SORT);
							setIsSortOpen(false);
							handleSort(orderSort, DATE_CREATED_SORT);
						}}
						startIcon={<DoneIcon />}
						className={appsSort === DATE_CREATED_SORT ? "checkedActive" : ""}
					>
						{DATE_CREATED_SORT}
					</Button>
					<Button
						onClick={() => {
							setAppsSort(LAST_VIEWED_SORT);
							setIsSortOpen(false);
							handleSort(orderSort, LAST_VIEWED_SORT);
						}}
						startIcon={<DoneIcon />}
						className={appsSort === LAST_VIEWED_SORT ? "checkedActive" : ""}
					>
						{LAST_VIEWED_SORT}
					</Button>
					<Divider />
					<span className="menuHead">Order</span>
					<Button
						onClick={() => {
							setOrderSort(NEWEST_ORDER_SORT);
							setIsSortOpen(false);
							handleSort(NEWEST_ORDER_SORT, appsSort);
						}}
						startIcon={<DoneIcon />}
						className={orderSort === NEWEST_ORDER_SORT ? "checkedActive" : ""}
					>
						{appsSort === ALPHABETICAL_SORT
							? NEWEST_ORDER_SORT_TEXT_ALPHABETICAL
							: NEWEST_ORDER_SORT_TEXT}
					</Button>
					<Button
						onClick={() => {
							setOrderSort(OLDEST_FIRST_SORT);
							setIsSortOpen(false);
							handleSort(OLDEST_FIRST_SORT, appsSort);
						}}
						startIcon={<DoneIcon />}
						className={orderSort === OLDEST_FIRST_SORT ? "checkedActive" : ""}
					>
						{appsSort === ALPHABETICAL_SORT
							? OLDEST_FIRST_SORT_TEXT_ALPHABETICAL
							: OLDEST_FIRST_SORT_TEXT}
					</Button>
				</Popover.Content>
			</Popover>
		</div>
	);
};

export default SortFilter;
