import React, { useEffect, useState } from "react";
import "./templateCard.scss";
import templatesPlaceholder from "../../assets/images/templatesImg.png";
import { Button } from "@contentful/f36-button";

type TemplateCardProps = {
	appData?: {
		_id: string;
		name: string;
		updatedAt: string;
		createdAt: string;
		isDeleted: boolean;
	};
	isBlankApp?: boolean;
	createNewApp: (newApp: { name: string; template?: string }) => Promise<void>;
	setIsCreateNewModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
	appName: string;
};

const TemplateCard: React.FunctionComponent<TemplateCardProps> = ({
	appData,
	isBlankApp,
	createNewApp,
	setIsCreateNewModalOpen,
	appName,
}) => {
	const [isMouseOver, setIsMouseOver] = useState(false);
	const [isCreatingNewApp, setIsCreatingNewApp] = useState<boolean>(false);
	const [isSelectDisabled, setIsSelectDisabled] = useState<boolean>(true);

	const handleCreateNewApp = async () => {
		setIsCreatingNewApp(true);
		await createNewApp(
			isBlankApp ? { name: appName } : { name: appName, template: appData._id }
		);
		setIsCreateNewModalOpen(false);
		setIsCreatingNewApp(false);
	};

	useEffect(() => {
		if (appName.length > 3) {
			setIsSelectDisabled(false);
		} else {
			setIsSelectDisabled(true);
		}
	}, [appName]);

	return (
		<div
			className="templateContainer"
			onMouseEnter={() => setIsMouseOver(true)}
			onMouseLeave={() => setIsMouseOver(false)}
		>
			<div className="imgWrapper">
				<img src={templatesPlaceholder} alt="" />
				{isMouseOver && (
					<div className="hoverDetails">
						<p className="templateTitle">Template name</p>
						<p className="templateDetails">
							Lorem ipsum dolor sit amet consectetur. In dolor mauris
							pellentesque sed. Semper malesuada faucibus posuere eu pharetra
							enim.{" "}
						</p>
						<Button
							isDisabled={isSelectDisabled}
							onClick={handleCreateNewApp}
							isLoading={isCreatingNewApp}
						>
							Select
						</Button>
					</div>
				)}
			</div>
			<div className="cardDetails">
				<div className="leftDeets">
					<p className="appName">{isBlankApp ? "Blank app" : appData?.name}</p>
					<p className="lastViewed">Uno</p>
				</div>
			</div>
		</div>
	);
};

export default TemplateCard;
