import { EmptyCallIcon } from "assets/icons/icons";
import * as React from "react";

type EmptyAPICallProps = {
  handleClick: () => void;
};

const EmptyAPICall: React.FunctionComponent<EmptyAPICallProps> = ({
  handleClick,
}) => {
  return (
    <div className="emptyWrapper noBgWrapper">
      <EmptyCallIcon />
      <p className="no addMg">This group is empty</p>
      <p className="sub">Add a call now to get started</p>
      <button onClick={handleClick}>Add call</button>
    </div>
  );
};

export default EmptyAPICall;
