import { useRef } from "react";
import axios, { AxiosError, AxiosHeaders } from "axios";
import { Button, Flex } from "@contentful/f36-components";
import { Button as AntButton } from "antd";
import {
	CaretDownIcon,
	DeleteIcon,
	SearchIcon,
	SuccessCheckIcon,
	XRemoveIcon,
} from "assets/icons/icons";
import Sidebar from "components/sidebar/Sidebar";
import Topbar from "components/topbar/Topbar";
import React, { useEffect, useState } from "react";
import { PlusIcon } from "@contentful/f36-icons";
import {
	Input,
	Modal,
	Segmented,
	Select,
	Tabs,
	Form,
	Switch,
	Checkbox,
	message,
	Spin,
} from "antd";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { CodeBlock, atomOneLight } from "react-code-blocks";
import Editor from "@monaco-editor/react";

import "./apiCollections.scss";
import { CALL_DEFINITION, RESPONSE_AND_TEST } from "utils/constants";
import "../../../components/filters/filters.scss";
import type { TabsProps } from "antd";
import TextArea from "antd/es/input/TextArea";
import EmptyGroups from "components/emptyAppsPageState/EmptyGroups";
import EmptyAPICall from "components/emptyAppsPageState/EmptyAPICall";
import ApiGroupCard from "components/apiGroupCard/ApiGroupCard";
import { useApiService } from "helpers/api-service";
import { FormListProps } from "antd/lib/form";

type APICollectionsProps = {};

export type APICall = {
	_id?: string;
	requestName: string;
	methodType: string;
	apiCollection: string;
	requestURL: string;
	headers?: { id: string; value: string }[];
	queryParams?: {
		name?: string;
		valueSource?: string;
		type?: string;
		value?: string;
	}[];
	variables?: {
		name?: string;
		type?: string;
		defaultValue?: boolean;
	}[];
	body?: {
		type?: string;
		body?: string | object;
	};
	customProxy?: {
		value?: boolean;
		proxyType?: {
			HTTP?: boolean;
			HTTPS?: boolean;
		};
		proxyServer?: string;
		proxyAuth?: boolean;
		username?: string;
		password?: string;
		proxyByPass?: string;
	};
	response?: {};
	cacheResult?: boolean;
	decodeResponse?: boolean;
	encodeBody?: boolean;
	class?: string;
	showMenuIcon?: boolean;
	isSaveButtonEnabled?: boolean;
	user?: {};
	createdAt?: string;
	updatedAt?: string;
	__v?: number;
};

export type GroupCall = {
	_id: string;
	name: string;
	apiCalls?: APICall[];
};

const APICollections: React.FunctionComponent<APICollectionsProps> = () => {
	const [renderCallTab, setRenderCallTab] = useState<string>(CALL_DEFINITION);
	const [testCallTab, setTestCallTab] = useState<string>("Headers");
	const [testDataType, setTestDataType] = useState<string>("json");
	const [response, setResponse] = useState<boolean>(false);
	const [responseHeaders, setResponseHeaders] = useState<object>({});
	const [responseBody, setResponseBody] = useState<object | unknown>();
	const [responseStatus, setResponseStatus] = useState<string>("-");
	const [responseStatusText, setResponseStatusText] = useState<string>("");
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
	const [showQueryParams, setShowQueryParams] = useState<boolean>(true);
	const [selectedGroup, setSelectedGroup] = useState<GroupCall>({
		_id: "",
		name: "",
		apiCalls: [],
	});
	const [newApiCallMethodType, setNewApiCallMethodType] =
		useState<string>("Select");
	const [isCreateGroupModalOpen, setIsCreateGroupModalOpen] =
		useState<boolean>(false);
	const [isCreateGroupLoading, setIsCreateGroupLoading] =
		useState<boolean>(false);
	const [isCreateCallLoading, setIsCreateCallLoading] =
		useState<boolean>(false);
	const [isUpdateCallLoading, setIsUpdateCallLoading] =
		useState<boolean>(false);
	const [isDeleteApiCallLoading, setIsDeleteApiCallLoading] =
		useState<boolean>(false);
	const [isRenameModalOpen, setIsRenameModalOpen] = useState<boolean>(false);
	const [renameGroupText, setRenameGroupText] = useState<string>(
		selectedGroup.name
	);
	const [renameGroupID, setRenameGroupID] = useState<string>(selectedGroup._id);
	const [isRenameGroupLoading, setIsRenameGroupLoading] =
		useState<boolean>(false);
	const [runEffectOnAutoToggle, setRunEffectOnAutoToggle] = useState<{
		value: boolean;
		isDelete: boolean;
	}>({ value: false, isDelete: false });
	const [isApiCollectionsLoading, setIsApiCollectionsLoading] =
		useState<boolean>(true);
	const [isSaveButtonDisabled, setIsSaveButtonDisabled] =
		useState<boolean>(true);

	// Advanced Settings State
	const [customProxy, setcustomProxy] = useState<boolean>(false);
	const [cacheApiResults, setCacheApiResults] = useState<boolean>(false);
	const [decodeResponse, setDecodeResponse] = useState<boolean>(false);
	const [proxyAuth, setProxyAuth] = useState<boolean>(false);
	const [httpProxyType, setHttpProxyType] = useState<boolean>(false);
	const [httpsProxyType, setHttpsProxyType] = useState<boolean>(false);

	const defaultApiCallObject: APICall = {
		requestName: "",
		methodType: "Select",
		requestURL: "",
		apiCollection: selectedGroup?._id,
		headers: [],
		queryParams: [],
		variables: [],
		body: {
			type: "JSON",
			body: {
				email: "eve.holt@reqres.in",
			},
		},
		customProxy: {
			value: false,
			proxyType: {
				HTTP: false,
				HTTPS: false,
			},
			proxyServer: "",
			proxyAuth: false,
			username: "",
			password: "",
			proxyByPass: "",
		},
		response: {},
		cacheResult: false,
		decodeResponse: false,
	};

	const [groupsData, setGroupsData] = useState<GroupCall[]>([]);
	const [selectedApiCall, setSelectedApiCall] =
		useState<APICall>(defaultApiCallObject);
	const [currentPanelID, setCurrentPanelID] = useState<string | string[]>(
		groupsData[0]?._id
	);
	const client = useApiService();
	const queryClient = useQueryClient();
	const [messageApi, contextHolder] = message.useMessage();

	const [newGroupName, setNewGroupName] = useState<string>("");

	const autoTogglePanel = (previousID: string | string[]) => {
		if (previousID) {
			setCurrentPanelID(undefined);
		}
		setTimeout(() => {
			setCurrentPanelID(selectedGroup._id);
		}, 100);
	};

	// Create Group Call
	const createGroupFn = async (groupName: object) => {
		const response = await client.post("/apigroups", groupName);
		return response.data;
	};

	const CreateGroupMutation = useMutation({
		mutationFn: createGroupFn,
		onSuccess(data, variables, context) {
			console.log(data);
			setIsCreateGroupLoading(false);
			setIsCreateGroupModalOpen(false);
			setNewGroupName("");
			messageApi.open({
				type: "success",
				content: "Group created",
				icon: <SuccessCheckIcon />,
			});
			queryClient.refetchQueries({
				queryKey: ["groupsList"],
				exact: true,
				type: "active",
			});
		},
		onError(error, variables, context) {
			console.log(error);
			setIsCreateGroupLoading(false);
		},
	});

	// Create Group Call
	const renameGroupFn = async (groupName: { name: string }) => {
		const response = await client.patch(
			`/apigroups/${selectedGroup._id || renameGroupID}`,
			groupName
		);
		return response.data;
	};

	const RenameGroupMutation = useMutation({
		mutationFn: renameGroupFn,
		onSuccess(data, variables, context) {
			console.log(data);
			setIsRenameModalOpen(false);
			setIsRenameGroupLoading(false);
			setRenameGroupText("");
			setSelectedGroup({ ...selectedGroup, name: data.name });
			messageApi.open({
				type: "success",
				content: "Group renamed",
				icon: <SuccessCheckIcon />,
			});
			queryClient.refetchQueries({
				queryKey: ["groupsList"],
				exact: true,
				type: "active",
			});
		},
		onError(error, variables, context) {
			console.log(error);
		},
	});

	const renameGroup = () => {
		setIsRenameGroupLoading(true);
		RenameGroupMutation.mutate({ name: renameGroupText });
	};

	// Get List of Groups
	const getGroupsFn = async () => {
		const response = await client.get("/apigroups");
		return response.data;
	};

	const {
		data: groupsList,
		status: groupsListStatus,
		refetch: refetchGroups,
	} = useQuery({
		queryKey: ["groupsList"],
		queryFn: getGroupsFn,
		onSuccess(data) {
			console.log(data);
			setGroupsData(data?.results);
			setIsApiCollectionsLoading(false);
		},
		onError(err) {
			console.error(err);
			setIsApiCollectionsLoading(false);
		},
		refetchOnMount: true,
	});

	useEffect(() => refetchGroups() as any, []);

	// Create Call
	const createCallFn = async (call: object) => {
		const response = await client.post("/apicalls", call);
		return response.data;
	};

	const CreateCallMutation = useMutation({
		mutationFn: createCallFn,
		onSuccess(data, variables, context) {
			console.log("after call", data);
			setIsCreateCallLoading(false);
			setSelectedApiCall(data);
			setRunEffectOnAutoToggle({ value: true, isDelete: false });
			autoTogglePanel(currentPanelID);
			messageApi.open({
				type: "success",
				content: "API call created",
				icon: <SuccessCheckIcon />,
			});
			queryClient.refetchQueries({
				queryKey: ["callsLists"],
				exact: true,
				type: "active",
			});
		},
		onError(error, variables, context) {
			console.log(error);
			setIsCreateCallLoading(false);
		},
	});

	const updateCallFn = async (call: object) => {
		const response = await client.patch(
			`/apicalls/${selectedApiCall._id}`,
			call
		);
		return response.data;
	};

	const UpdateCallMutation = useMutation({
		mutationFn: updateCallFn,
		onSuccess(data) {
			setIsUpdateCallLoading(false);
			setSelectedApiCall(data);
			setRunEffectOnAutoToggle({ value: true, isDelete: false });
			autoTogglePanel(currentPanelID);
			messageApi.open({
				type: "success",
				content: "API call updated",
				icon: <SuccessCheckIcon />,
			});
		},
		onError(error) {
			console.log(error);
			setIsUpdateCallLoading(false);
		},
	});

	// Delete Call
	const deleteApiCallFn = async () => {
		const response = await client.delete(`/apicalls/${selectedApiCall._id}`);
		return response.data;
	};

	const handleMethodTypeChange = (value: string) => {
		if (selectedApiCall._id) {
			setSelectedApiCall({
				...selectedApiCall,
				methodType: value,
				isSaveButtonEnabled: true,
			});
		} else {
			setSelectedApiCall({ ...selectedApiCall, methodType: value });
		}

		console.log(value);

		setNewApiCallMethodType(value);
		switch (value) {
			case "post":
			case "put":
			case "patch": {
				setShowQueryParams(false);
				break;
			}
			case "delete":
			case "get": {
				setShowQueryParams(true);
				break;
			}
		}
	};

	const createCall = () => {
		setIsCreateCallLoading(true);
		CreateCallMutation.mutate(selectedApiCall);
	};

	const updateCall = () => {
		setIsUpdateCallLoading(true);
		const {
			_id: _,
			apiCollection: __,
			user: ___,
			createdAt: ____,
			updatedAt: _____,
			__v: ______,
			class: _______,
			showMenuIcon: ________,
			isSaveButtonEnabled: _________,
			...newObj
		} = selectedApiCall;
		UpdateCallMutation.mutate(newObj);
	};

	//       TEST CALLS

	const resetResponse = () => {
		setResponse(false);
		setResponseBody({});
		setResponseHeaders({});
		setResponseStatus("-");
		setResponseStatusText("");
	};

	//  GET Test
	const {
		data: testGet,
		status: testGetStatus,
		refetch: refetchTestGet,
	} = useQuery({
		queryKey: ["testGet"],
		queryFn: async () => {
			const response = await axios({
				method: selectedApiCall?.methodType.toLowerCase(),
				url: selectedApiCall?.requestURL,
				params: selectedApiCall?.queryParams,
			});
			return response;
		},
		onSuccess(data) {
			setResponseHeaders(data?.headers as AxiosHeaders);
			setResponseBody(data?.data);
			setResponseStatus((data?.status).toString());
			setResponseStatusText(data?.statusText);
		},
		onError(err: AxiosError) {
			setResponseHeaders(err?.response?.headers as AxiosHeaders);
			setResponseBody(err?.response?.data);
			setResponseStatus((err?.response.status).toString());
			setResponseStatusText(err?.response.statusText);
			console.log(err?.response.statusText);
		},
		retry: 0,
		enabled: false,
	});

	// POST Test
	const TestPostMutation = useMutation({
		mutationFn: async () => {
			const response = await axios({
				method: selectedApiCall?.methodType.toLowerCase(),
				url: selectedApiCall?.requestURL,
				data: selectedApiCall?.body.body,
			});
			return response;
		},
		onSuccess(data, variables, context) {
			console.log(data);
			setResponseHeaders(data?.headers as AxiosHeaders);
			setResponseBody(data?.data);
			setResponseStatus((data?.status).toString());
			setResponseStatusText(data?.statusText);
		},
		onError(err: AxiosError) {
			setResponseHeaders(err?.response?.headers as AxiosHeaders);
			setResponseBody(err?.response?.data);
			setResponseStatus((err?.response.status).toString());
			setResponseStatusText(err?.response.statusText);
			console.log(err?.response.statusText);
		},
	});

	const testApiCall = () => {
		setResponse(true);

		switch (selectedApiCall?.methodType.toLowerCase()) {
			case "get":
			case "delete":
				refetchTestGet();
				break;
			case "post":
			case "patch":
			case "put":
				TestPostMutation.mutate();
				break;
			default:
				setResponse(false);
				messageApi.open({
					type: "error",
					content: "No URL to Test",
				});
				break;
		}
	};

	const resetApiCallFields = () => {
		setSelectedApiCall(defaultApiCallObject);
	};

	const RenderHeadersTab = () => {
		const updateHeader = (id: string, value: string) => {
			const selectedHeader = selectedApiCall.headers.filter(
				(header) => header.id === id
			);
			const updatedSelectedHeader = { id: selectedHeader[0].id, value: value };
			const headersArray = selectedApiCall.headers.map(
				(obj) => [updatedSelectedHeader].find((o) => o.id === obj.id) || obj
			);
			if (selectedApiCall._id) {
				setSelectedApiCall({
					...selectedApiCall,
					headers: headersArray,
					isSaveButtonEnabled: true,
				});
			} else {
				setSelectedApiCall({
					...selectedApiCall,
					headers: headersArray,
				});
			}
		};

		const addNewHeader = () => {
			const headers = [
				...selectedApiCall.headers,
				{ id: String(Math.random()), value: "" },
			];
			if (selectedApiCall._id) {
				setSelectedApiCall({
					...selectedApiCall,
					headers: [...headers],
					isSaveButtonEnabled: true,
				});
			} else {
				setSelectedApiCall({ ...selectedApiCall, headers: [...headers] });
			}
		};

		const removeForm = (id: string) => {
			const updatedForms = selectedApiCall.headers.filter(
				(apiCall) => apiCall.id !== id
			);
			setSelectedApiCall({
				...selectedApiCall,
				headers: updatedForms,
			});
		};

		return (
			<div className="headers">
				<form name="dynamic_form_nest_item" autoComplete="off">
					{selectedApiCall?.headers?.map((apicall) => (
						<div key={apicall.id}>
							<div className="flex-header" key={apicall.id}>
								<Form.Item className="header-input">
									<Input
										onChange={(e) => updateHeader(apicall.id, e.target.value)}
										placeholder="Content-Type: application/json"
										value={apicall.value}
									/>
								</Form.Item>
								<button
									type="button"
									className="closeIcon"
									onClick={() => removeForm(apicall.id)}
								>
									<XRemoveIcon />
								</button>
							</div>
						</div>
					))}
					<button
						className="addHead"
						onClick={(e) => {
							e.preventDefault();
							addNewHeader();
						}}
					>
						<PlusIcon />
						<span>Add header</span>
					</button>
				</form>
			</div>
		);
	};

	const RenderQueryParamsTab = () => {
		const onFinish = (values: Object) => {
			console.log("Received values of form:", values);
		};

		return (
			<div className="headers qParams">
				<Form
					name="dynamic_form_nest_item"
					onFinish={onFinish}
					autoComplete="off"
				>
					<Form.List name="queryParams">
						{(fields, { add, remove }) => (
							<>
								{fields.map(({ key, name, ...restField }) => (
									<div className="flex-header" key={key}>
										<div className="flex-qParams">
											<Form.Item
												{...restField}
												name={[name, "queryName"]}
												className="queryName"
											>
												<label htmlFor="name">Name</label>
												<Input />
											</Form.Item>
											<Form.Item
												{...restField}
												name={[name, "valueSource"]}
												className="valueSource"
											>
												<label htmlFor="name">Value source</label>
												<Select
													defaultValue="specific-value"
													suffixIcon={<CaretDownIcon />}
													options={[
														{
															value: "specific-value",
															label: "Specific value",
														},
														{
															value: "from-variable",
															label: "From variable",
														},
													]}
												/>
											</Form.Item>
											<Form.Item
												{...restField}
												name={[name, "type"]}
												className="type"
											>
												<label htmlFor="name">Type</label>
												<Select
													defaultValue="Select"
													suffixIcon={<CaretDownIcon />}
													options={[
														{
															value: "string",
															label: "String",
														},
														{ value: "integer", label: "Integer" },
														{ value: "double", label: "Double" },
														{ value: "boolean", label: "Boolean" },
														{ value: "json", label: "JSON" },
													]}
												/>
											</Form.Item>
											<Form.Item
												{...restField}
												name={[name, "value"]}
												className="value"
											>
												<label htmlFor="name">Value</label>
												<Input />
											</Form.Item>
											<Form.Item>
												<AntButton type="primary" htmlType="submit">
													Submit
												</AntButton>
											</Form.Item>
											<button
												className="closeIcon padded"
												onClick={() => {
													remove(key);
												}}
											>
												<XRemoveIcon />
											</button>
										</div>
									</div>
								))}
								<Form.Item>
									<button className="addHead" onClick={() => add()}>
										<PlusIcon />
										<span>Add Query parameter</span>
									</button>
								</Form.Item>
							</>
						)}
					</Form.List>
				</Form>
			</div>
		);
	};

	const RenderBodyTab = () => {
		return (
			<div className="body headers">
				<div className="flex-header bodyColumn">
					<label htmlFor="body">Select body</label>
					<Select
						defaultValue="Select"
						suffixIcon={<CaretDownIcon />}
						style={{ width: 180 }}
						options={[
							{
								value: "none",
								label: "None",
							},
							{ value: "json", label: "JSON" },
							{ value: "text", label: "Text" },
							{
								value: "x-wwww-form-urlencoded",
								label: "x-wwww-form-urlencoded",
							},
							{ value: "multipart", label: "Multipart" },
						]}
					/>
				</div>
			</div>
		);
	};

	const RenderVariablesTab = () => {
		const onFinish = (values: any) => {
			console.log("Received values of form:", values);
		};

		return (
			<div className="headers variables">
				<Form
					name="dynamic_form_nest_item"
					onFinish={onFinish}
					autoComplete="off"
				>
					<Form.List name="headers">
						{(fields, { add, remove }) => (
							<>
								{fields.map(({ key, name, ...restField }) => (
									<div className="flex-header" key={key}>
										<div className="flex-qParams">
											<Form.Item
												{...restField}
												name={name}
												className="queryName"
											>
												<label htmlFor="name">Name</label>
												<Input />
											</Form.Item>
											<Form.Item {...restField} name={name} className="type">
												<label htmlFor="name">Type</label>
												<Select
													defaultValue="Select"
													suffixIcon={<CaretDownIcon />}
													options={[
														{
															value: "string",
															label: "String",
														},
														{ value: "integer", label: "Integer" },
														{ value: "double", label: "Double" },
														{ value: "boolean", label: "Boolean" },
														{ value: "json", label: "JSON" },
													]}
												/>
											</Form.Item>
											<Form.Item {...restField} name={name} className="value">
												<label htmlFor="name">Default Value</label>
												<Input />
											</Form.Item>
											<button
												className="closeIcon padded"
												onClick={() => remove(name)}
											>
												<XRemoveIcon />
											</button>
										</div>
									</div>
								))}
								<Form.Item>
									<button className="addHead" onClick={() => add()}>
										<PlusIcon />
										<span>Add Variable</span>
									</button>
								</Form.Item>
							</>
						)}
					</Form.List>
				</Form>
			</div>
		);
	};

	const RenderAdvancedSettings = () => {
		return (
			<div className="advSettings">
				<div className="proxHead">
					<p>Proxy configurations for sending requests</p>
					<p className="light redWdth">
						Specify a proxy setting to act as an intermediary for requests sent
						through the Builder in Postman. These configurations do not apply to
						any Postman services.
					</p>
				</div>
				<div className="flexSwitch">
					<div className="textFlx">
						<p className="headPrx">Add a custom proxy configuration</p>
						<p className="light">
							Switch on to route this API call securely via a cloud funtion.
						</p>
					</div>
					<Switch
						onChange={(checked) => {
							setSelectedApiCall({
								...selectedApiCall,
								customProxy: {
									...selectedApiCall.customProxy,
									value: checked,
								},
							});
							setcustomProxy(checked);
						}}
						size="small"
						checked={
							selectedApiCall
								? selectedApiCall?.customProxy?.value
								: customProxy
						}
					/>
				</div>
				{selectedApiCall?.customProxy?.value && (
					<div className="customProxySettings">
						<div className="advFlex proxType">
							<p>Proxy Type</p>
							<div className="rhs checkBoxes">
								<Checkbox
									checked={selectedApiCall?.customProxy?.proxyType?.HTTP}
									onChange={(e) => {
										setHttpProxyType(e.target.checked);
										setSelectedApiCall({
											...selectedApiCall,
											customProxy: {
												...selectedApiCall.customProxy,
												proxyType: {
													...selectedApiCall.customProxy.proxyType,
													HTTP: e.target.checked,
												},
											},
										});
									}}
									defaultChecked={selectedApiCall?.customProxy?.proxyType?.HTTP}
								>
									HTTP
								</Checkbox>
								<Checkbox
									checked={selectedApiCall?.customProxy?.proxyType?.HTTPS}
									onChange={(e) => {
										setHttpsProxyType(e.target.checked);
										setSelectedApiCall({
											...selectedApiCall,
											customProxy: {
												...selectedApiCall.customProxy,
												proxyType: {
													...selectedApiCall.customProxy.proxyType,
													HTTPS: e.target.checked,
												},
											},
										});
									}}
									defaultChecked={
										selectedApiCall?.customProxy?.proxyType?.HTTPS
									}
								>
									HTTPS
								</Checkbox>
							</div>
						</div>
						<div className="advFlex proxyServer">
							<p>Proxy Server</p>
							<div className="rhs pxServerInput">
								<Input className="proxy" placeholder="127.0.0.1" /> :{" "}
								<Input placeholder="8080" type="number" />
							</div>
						</div>
						<div className="advFlex proxyAuth">
							<p>Proxy Auth.</p>
							<div className="rhs">
								<Switch
									defaultChecked={proxyAuth}
									onChange={(checked) => setProxyAuth(checked)}
									size="small"
								/>
							</div>
						</div>
						{proxyAuth && (
							<>
								<div className="advFlex proxyAuth">
									<p>Username</p>
									<div className="rhs">
										<Input />
									</div>
								</div>
								<div className="advFlex proxyAuth">
									<p>Password</p>
									<div className="rhs PASSW">
										<Input.Password />
									</div>
								</div>
							</>
						)}
						<div className="advFlex proxyBypass">
							<p>Proxy Bypass</p>
							<div className="rhs">
								<TextArea
									name="proxyBypass"
									id="proxyBypass"
									cols={30}
									rows={2}
									placeholder="Enter comma separated hosts to bypass proxy settings. Example : 127.0.0.1, localhost, *.example.com"
								></TextArea>
							</div>
						</div>
					</div>
				)}
				<div className="flexSwitch">
					<div className="textFlx">
						<p className="headPrx">Cache API results</p>
						<p className="light">
							Switch on to route this API call securely via a cloud funtion.
						</p>
					</div>
					<Switch
						defaultChecked={cacheApiResults}
						onChange={(checked) => setCacheApiResults(checked)}
						size="small"
					/>
				</div>
				<div className="flexSwitch">
					<div className="textFlx">
						<p className="headPrx">Decode response as UTF-8</p>
						<p className="light">Switch on to force response as UTF-8.</p>
					</div>
					<Switch
						defaultChecked={decodeResponse}
						onChange={(checked) => setDecodeResponse(checked)}
						size="small"
					/>
				</div>
			</div>
		);
	};

	const items: TabsProps["items"] = [
		{
			key: "1",
			label: `Headers`,
			children: <RenderHeadersTab />,
		},
		{
			key: "2",
			label: showQueryParams ? `Query parameters` : "Body",
			children: showQueryParams ? <RenderQueryParamsTab /> : <RenderBodyTab />,
		},
		{
			key: "3",
			label: `Variables`,
			children: <RenderVariablesTab />,
		},
		{
			key: "4",
			label: `Advanced settings`,
			children: <RenderAdvancedSettings />,
		},
	];

	const monacoOptions = {
		acceptSuggestionOnCommitCharacter: true,
		acceptSuggestionOnEnter: "on",
		accessibilitySupport: "auto",
		autoIndent: false,
		automaticLayout: true,
		codeLens: false,
		colorDecorators: true,
		contextmenu: true,
		cursorBlinking: "blink",
		cursorSmoothCaretAnimation: false,
		cursorStyle: "line",
		disableLayerHinting: false,
		disableMonospaceOptimizations: false,
		dragAndDrop: false,
		fixedOverflowWidgets: false,
		folding: true,
		foldingStrategy: "auto",
		fontLigatures: false,
		formatOnPaste: false,
		formatOnType: false,
		hideCursorInOverviewRuler: false,
		highlightActiveIndentGuide: true,
		links: true,
		mouseWheelZoom: false,
		multiCursorMergeOverlapping: true,
		multiCursorModifier: "alt",
		overviewRulerBorder: false,
		overviewRulerLanes: 2,
		quickSuggestions: true,
		quickSuggestionsDelay: 100,
		readOnly: true,
		renderControlCharacters: false,
		renderFinalNewline: true,
		renderIndentGuides: true,
		renderLineHighlight: "all",
		renderWhitespace: "none",
		revealHorizontalRightPadding: 30,
		roundedSelection: true,
		scrollBeyondLastColumn: 5,
		scrollBeyondLastLine: false,
		selectOnLineNumbers: true,
		selectionClipboard: false,
		selectionHighlight: false,
		showFoldingControls: "mouseover",
		smoothScrolling: true,
		suggestOnTriggerCharacters: true,
		wordBasedSuggestions: true,
		wordSeparators: "~!@#$%^&*()-=+[{]}|;:'\",.<>/?",
		wordWrap: "on",
		wordWrapBreakAfterCharacters: "\t})]?|&,;",
		wordWrapBreakBeforeCharacters: "{([+",
		wordWrapBreakObtrusiveCharacters: ".",
		wordWrapColumn: 80,
		wordWrapMinified: true,
		wrappingIndent: "none",
	};

	const RenderTestBodyTab = () => {
		return (
			<div className="">
				<Editor
					height="35vh"
					// defaultLanguage="JSON"
					language={testDataType}
					options={monacoOptions}
					theme="light"
					// value={
					// 	testGetStatus === "success" || testGetStatus === "error"
					// 		? JSON.stringify(responseBody, null, "\t")
					// 		: testGetStatus === "loading"
					// 		? "Loading"
					// 		: null
					// }
					value={JSON.stringify(responseBody, null, "\t")}
				/>
			</div>
		);
	};

	const RenderTestHeaderTab = () => {
		return (
			<div>
				<Editor
					height="35vh"
					language={testDataType}
					options={monacoOptions}
					theme="light"
					// value={
					// 	testGetStatus === "success" || testGetStatus === "error"
					// 		? JSON.stringify(responseHeaders, null, "\t")
					// 		: testGetStatus === "loading"
					// 		? "Loading"
					// 		: null
					// }
					value={JSON.stringify(responseHeaders, null, "\t")}
				/>
			</div>
		);
	};

	const testItems: TabsProps["items"] = [
		{
			key: "1",
			label: "Body",
			children: <RenderTestBodyTab />,
		},
		{
			key: "2",
			label: "Headers",
			children: <RenderTestHeaderTab />,
		},
	];

	const DeleteModalFooter = () => {
		return (
			<div className="renameFooter">
				<button onClick={() => setIsDeleteModalOpen(false)} className="cancel">
					Cancel
				</button>
				<Button
					className="delete"
					onClick={() => {
						setIsDeleteApiCallLoading(true);
						deleteApiCallFn().then(() => {
							setIsDeleteModalOpen(false);
							setIsDeleteApiCallLoading(false);
							setRunEffectOnAutoToggle({ value: true, isDelete: true });
							autoTogglePanel(currentPanelID);
							setSelectedGroup({
								_id: "",
								name: "",
								apiCalls: [],
							});
							messageApi.open({
								type: "success",
								content: "API call deleted",
								icon: <DeleteIcon />,
							});
						});
					}}
					isDisabled={isDeleteApiCallLoading}
					isLoading={isDeleteApiCallLoading}
				>
					Delete
				</Button>
			</div>
		);
	};

	const RenderCreateGroupModalFooter = () => {
		return (
			<div className="renameFooter">
				<button
					onClick={() => {
						setIsCreateGroupModalOpen(false);
					}}
					className="cancel"
				>
					Cancel
				</button>
				<Button
					className="update"
					onClick={() => {
						setIsCreateGroupLoading(true);
						CreateGroupMutation.mutate({ name: newGroupName });
					}}
					isDisabled={newGroupName.length < 1 || isCreateGroupLoading}
					isLoading={isCreateGroupLoading}
				>
					Create group
				</Button>
			</div>
		);
	};

	const RenameModalFooter = () => {
		return (
			<div className="renameFooter">
				<button onClick={() => setIsRenameModalOpen(false)} className="cancel">
					Cancel
				</button>
				<Button
					isLoading={isRenameGroupLoading}
					isDisabled={isRenameGroupLoading}
					onClick={renameGroup}
					className="update"
				>
					Rename
				</Button>
			</div>
		);
	};

	useEffect(() => {
		setRenameGroupText(selectedGroup.name);
	}, [isRenameModalOpen]);

	const editorRef = useRef(null);

	// function handleEditorDidMount(editor, monaco) {
	// 	editorRef.current = editor;
	// }

	// function showValue() {
	// 	alert(editorRef.current.getValue());
	// }

	return (
		<Flex>
			<Sidebar />
			<main>
				{contextHolder}
				<Topbar />
				<div className="padLayout-Api">
					<div className="topLayout API-top">
						<h3 className="pageName">API Collections</h3>
						<div className="searchNCreate">
							<Input prefix={<SearchIcon />} placeholder="Search" allowClear />
							<Button
								className="createNew"
								startIcon={<PlusIcon />}
								variant="primary"
								onClick={() => setIsCreateGroupModalOpen(true)}
							>
								Create new
							</Button>
						</div>
					</div>
					{isApiCollectionsLoading ? (
						<div className="collections-spin">
							<Spin />
						</div>
					) : (
						<div className="flex-collection">
							<div className="callNames">
								<div className="api-calls">
									{groupsListStatus === "success"
										? groupsData.map((group) => (
												<ApiGroupCard
													group={group}
													key={group._id}
													setSelectedApiCall={setSelectedApiCall}
													selectedApiCall={selectedApiCall}
													resetApiCallFields={resetApiCallFields}
													setSelectedGroup={setSelectedGroup}
													defaultApiCallObject={defaultApiCallObject}
													currentPanelID={currentPanelID}
													setCurrentPanelID={setCurrentPanelID}
													refetchGroups={refetchGroups}
													setNewGroupName={setNewGroupName}
													runEffectOnAutoToggle={runEffectOnAutoToggle}
													setRunEffectOnAutoToggle={setRunEffectOnAutoToggle}
													renameGroup={renameGroup}
													setRenameGroupText={setRenameGroupText}
													renameGroupText={renameGroupText}
													resetResponse={resetResponse}
													setRenameGroupID={setRenameGroupID}
												/>
										  ))
										: null}
								</div>
							</div>
							<div className="call-actions">
								{groupsData.length > 0 ? (
									<>
										{selectedGroup?._id ? (
											<>
												<div className="editGrp">
													<p className="grpName">{selectedGroup.name}</p>
												</div>
												{selectedGroup?.apiCalls?.length > 0 ? (
													<div className="configCont">
														<p className="configTitle">Configure API call</p>
														<p className="configTxt">
															Provide the name and configuration for this API
															call.
														</p>
														<Segmented
															options={[CALL_DEFINITION, RESPONSE_AND_TEST]}
															onChange={(prevValue) => {
																prevValue !== CALL_DEFINITION
																	? setRenderCallTab(RESPONSE_AND_TEST)
																	: setRenderCallTab(CALL_DEFINITION);
															}}
														/>
														{renderCallTab === CALL_DEFINITION ? (
															<div className="callDefinition">
																<div className="formField">
																	<label htmlFor="reqName">Request name</label>
																	<Input
																		placeholder="Enter API name"
																		onChange={(e) => {
																			if (selectedApiCall._id) {
																				setSelectedApiCall({
																					...selectedApiCall,
																					requestName: e.target.value,
																					isSaveButtonEnabled: true,
																				});
																			} else {
																				setSelectedApiCall({
																					...selectedApiCall,
																					requestName: e.target.value,
																				});
																			}
																		}}
																		value={selectedApiCall?.requestName}
																	/>
																</div>
																<div className="flexForm">
																	<div className="formField method">
																		<label htmlFor="method">Method type</label>
																		<Select
																			defaultValue="Select"
																			style={{ width: 120 }}
																			onChange={handleMethodTypeChange}
																			suffixIcon={<CaretDownIcon />}
																			options={[
																				{ value: "GET", label: "GET" },
																				{ value: "POST", label: "POST" },
																				{ value: "DELETE", label: "DELETE" },
																				{ value: "PUT", label: "PUT" },
																				{ value: "PATCH", label: "PATCH" },
																			]}
																			value={
																				selectedApiCall?.methodType ||
																				newApiCallMethodType
																			}
																		/>
																	</div>
																	<div className="formField reqURL">
																		<label htmlFor="reqUrl">
																			Request URL{" "}
																			<span className="req">(required)</span>
																		</label>
																		<Input
																			onChange={(e) => {
																				if (selectedApiCall._id) {
																					setSelectedApiCall({
																						...selectedApiCall,
																						requestURL: e.target.value,
																						isSaveButtonEnabled: true,
																					});
																				} else {
																					setSelectedApiCall({
																						...selectedApiCall,
																						requestURL: e.target.value,
																					});
																				}
																			}}
																			value={selectedApiCall?.requestURL}
																			placeholder="Enter request URL"
																		/>
																	</div>
																</div>
																<div className="tabs">
																	<Tabs defaultActiveKey="1" items={items} />
																</div>
																<div className="actionButtons">
																	<div className="leftBtns">
																		{selectedApiCall?._id && (
																			<Button
																				onClick={() =>
																					setIsDeleteModalOpen(true)
																				}
																				className="delete"
																			>
																				Delete
																			</Button>
																		)}
																	</div>
																	<div className="rightBtns">
																		{!selectedApiCall?._id && (
																			<Button className="cancel">Cancel</Button>
																		)}
																		{!selectedApiCall?._id && (
																			<Button
																				onClick={createCall}
																				className="blue"
																				isDisabled={
																					selectedApiCall?.requestURL === "" ||
																					selectedApiCall?.requestName === "" ||
																					isCreateCallLoading
																				}
																				isLoading={isCreateCallLoading}
																			>
																				Add call
																			</Button>
																		)}
																		{selectedApiCall?._id && (
																			<Button
																				onClick={updateCall}
																				className="blue"
																				isDisabled={
																					!selectedApiCall?.isSaveButtonEnabled ||
																					isUpdateCallLoading
																				}
																				isLoading={isUpdateCallLoading}
																			>
																				Save
																			</Button>
																		)}
																	</div>
																</div>
															</div>
														) : (
															<div className="callDefinition respTest">
																<div className="formField reqURL">
																	<label htmlFor="reqUrl">
																		Request URL{" "}
																		<span className="req">(required)</span>
																	</label>
																	<Input
																		placeholder="Enter request URL"
																		value={selectedApiCall?.requestURL}
																		readOnly
																	/>
																</div>
																<div className="respMain">
																	<p className="title">Preview</p>
																	<div className="testContainer">
																		<div className="tabContainer">
																			<Segmented
																				options={["Headers", "Response"]}
																				onChange={(prevValue) => {
																					prevValue !== "Headers"
																						? setTestCallTab("Response")
																						: setTestCallTab("Headers");
																				}}
																			/>
																			<Button
																				size="small"
																				className="test"
																				type="submit"
																				onClick={() => testApiCall()}
																				isDisabled={
																					!selectedApiCall?.requestURL
																				}
																			>
																				Test API call
																			</Button>
																		</div>

																		{response == true ? (
																			testCallTab === "Headers" ? (
																				<>
																					<div className="testHeader">
																						<Select
																							defaultValue={"JSON"}
																							onChange={(value) =>
																								setTestDataType(value)
																							}
																							suffixIcon={<CaretDownIcon />}
																							options={[
																								{
																									value: "json",
																									label: "JSON",
																								},
																								{ value: "xml", label: "XML" },
																								{
																									value: "html",
																									label: "HTML",
																								},
																								{
																									value: "text",
																									label: "Text",
																								},
																								{
																									value: "auto",
																									label: "Auto",
																								},
																							]}
																						/>
																						<div className="status">
																							<p className="statusText">
																								STATUS:
																							</p>
																							<p
																								className={
																									responseStatus === "200"
																										? "statusText greenText"
																										: responseStatus === "-"
																										? "statusText"
																										: "statusText redText"
																								}
																							>
																								{`${responseStatus} ${responseStatusText}`}
																							</p>
																						</div>
																					</div>
																					<div className="headerSnippetCont">
																						<Editor
																							height="40vh"
																							defaultLanguage="JSON"
																							language={testDataType}
																							options={monacoOptions}
																							theme="light"
																							value={JSON.stringify(
																								selectedApiCall?.headers
																							)}
																						/>
																					</div>
																				</>
																			) : (
																				<>
																					<div className="testResponse">
																						<div className="headerBar">
																							<Tabs
																								defaultActiveKey="1"
																								items={testItems}
																								tabBarExtraContent={
																									<>
																										<Select
																											defaultValue={"JSON"}
																											onChange={(value) =>
																												setTestDataType(value)
																											}
																											suffixIcon={
																												<CaretDownIcon />
																											}
																											options={[
																												{
																													value: "json",
																													label: "JSON",
																												},
																												{
																													value: "xml",
																													label: "XML",
																												},
																												{
																													value: "html",
																													label: "HTML",
																												},
																												{
																													value: "text",
																													label: "Text",
																												},
																												{
																													value: "auto",
																													label: "Auto",
																												},
																											]}
																										/>
																										<div className="status">
																											<p className="statusText">
																												STATUS:
																											</p>
																											<p
																												className={
																													responseStatus ===
																													"200"
																														? "statusText greenText"
																														: responseStatus ===
																														  "-"
																														? "statusText"
																														: "statusText redText"
																												}
																											>
																												{`${responseStatus} ${responseStatusText}`}
																											</p>
																										</div>
																									</>
																								}
																							/>
																						</div>
																					</div>
																				</>
																			)
																		) : (
																			<div className="noResponse">
																				<p className="noResponseTitle">
																					No Response
																				</p>
																				<p className="noResponseText">
																					Test API call to get a response
																				</p>
																				<Button
																					size="small"
																					className="test"
																					type="submit"
																					onClick={() => testApiCall()}
																					isDisabled={
																						!selectedApiCall?.requestURL
																					}
																				>
																					Test API call
																				</Button>
																			</div>
																		)}
																	</div>
																</div>
															</div>
														)}
													</div>
												) : (
													<>
														<div className="emptycont">
															<EmptyAPICall
																handleClick={() => {
																	resetApiCallFields();
																	setSelectedGroup({
																		...selectedGroup,
																		apiCalls: [defaultApiCallObject],
																	});
																}}
															/>
														</div>
													</>
												)}
											</>
										) : (
											<div className="noSelections">
												<div className="text">
													<p className="header">No API calls defined</p>
													<p className="subText">
														Select an API call to edit or Create a API call to
														get started
													</p>
												</div>
											</div>
										)}
									</>
								) : (
									<div className="emptycont">
										<EmptyGroups
											handleClick={() => setIsCreateGroupModalOpen(true)}
										/>
									</div>
								)}
							</div>
						</div>
					)}
				</div>
			</main>
			<Modal
				title="Delete api call"
				centered
				open={isDeleteModalOpen}
				onOk={() => setIsDeleteModalOpen(false)}
				onCancel={() => setIsDeleteModalOpen(false)}
				className="deleteModal"
				closable={false}
				width="320px"
				footer={<DeleteModalFooter />}
			>
				<p>Are you sure you want to delete this api call?</p>
			</Modal>
			<Modal
				title="Create new group"
				centered
				open={isCreateGroupModalOpen}
				onOk={() => setIsCreateGroupModalOpen(false)}
				onCancel={() => setIsCreateGroupModalOpen(false)}
				className="renameModal"
				closable={false}
				footer={<RenderCreateGroupModalFooter />}
				width="300px"
			>
				<p>Group name</p>
				<Input
					placeholder="Enter group name"
					onChange={(e) => setNewGroupName(e.target.value)}
					value={newGroupName}
				/>
			</Modal>
			<Modal
				title="Rename group"
				centered
				open={isRenameModalOpen}
				onOk={() => setIsRenameModalOpen(false)}
				onCancel={() => setIsRenameModalOpen(false)}
				className="renameModal"
				closable={false}
				footer={<RenameModalFooter />}
				width="320px"
			>
				<p>Group name</p>
				<Input
					onChange={(e) => setRenameGroupText(e.target.value)}
					placeholder="Enter group name"
					value={renameGroupText}
				/>
			</Modal>
		</Flex>
	);
};

export default APICollections;
