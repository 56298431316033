import React, { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { GoogleOAuthProvider } from "@react-oauth/google";
import Spinner from "../components/spinner/spinner";

import { Spin } from "antd";

import RouteContainer from "./route-container";
import { AuthServiceProvider } from "../helpers/auth-service";
import { ApiServiceProvider } from "../helpers/api-service";
import { MessageServiceProvider } from "../helpers/toast";
import { REACT_APP_GOOGLE_CLIENT_ID } from "../utils/env";
import { UserServiceProvider } from "../helpers/user-service";

const queryCache = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			retry: 2,
			staleTime: 30000,
		},
		mutations: {},
	},
});

// console.log(process);

type AppProps = {};

const App: React.FC<AppProps> = () => {
	return (
		<GoogleOAuthProvider clientId={REACT_APP_GOOGLE_CLIENT_ID}>
			<QueryClientProvider client={queryCache}>
				<AuthServiceProvider>
					<ApiServiceProvider>
						<BrowserRouter>
							<MessageServiceProvider>
								<UserServiceProvider>
									<Suspense fallback={<Spinner />}>
										<RouteContainer />
									</Suspense>
								</UserServiceProvider>
							</MessageServiceProvider>
						</BrowserRouter>
					</ApiServiceProvider>
				</AuthServiceProvider>
			</QueryClientProvider>
		</GoogleOAuthProvider>
	);
};

export default App;
