import * as React from "react";
import "./badge.scss";

type ApiBadgeProps = {
  type: string;
};

const ApiBadge: React.FunctionComponent<ApiBadgeProps> = ({ type }) => {
  return (
    <span
      className={
        type === "GET"
          ? "api-badge get"
          : type === "POST"
          ? "api-badge post"
          : type === "DELETE"
          ? "api-badge delete"
          : type === "PATCH"
          ? "api-badge patch"
          : type === "PUT"
          ? "api-badge put"
          : "api-badge"
      }
    >
      <span>{type.toUpperCase()}</span>
    </span>
  );
};

export default ApiBadge;
