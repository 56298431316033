import React, { createContext, useContext, useState, useMemo } from "react";
import axios from "axios";

// COOKIE KEY WHERE AUTH TOKEN IS STORED
const COOKIE_KEY: string = "UNO_auth_token";

type User = {
	firstName: string;
	lastName: string;
	role: string;
	email: string;
};

interface ContextType {
	token: string | null;
	setToken: (token: string | null, expires: number) => void;
	clearToken: () => void;
	// user: User;
}

const AuthServiceContext = createContext<ContextType | null>(null);

interface Provider {
	children: React.ReactElement;
}

export const AuthServiceProvider: React.FC<Provider> = ({ children }) => {
	const [token, updateToken] = useState(() => {
		const v = document.cookie.match("(^|;) ?" + COOKIE_KEY + "=([^;]*)(;|$)");
		return v ? v[2] : null;
	});

	const setToken = (token: string | null, expires: number) => {
		const d = new Date();
		d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * expires);
		document.cookie = `${COOKIE_KEY}=${token};path=/;expires=${d.toUTCString()}`;
		updateToken(token);
	};

	const clearToken = () => {
		setToken(null, -1);
	};

	return (
		<AuthServiceContext.Provider value={{ token, setToken, clearToken }}>
			{children}
		</AuthServiceContext.Provider>
	);
};

export function useAuthService() {
	return useContext(AuthServiceContext);
}
