import React, { useState } from "react";
import "./gridCard.scss";
import { Button, Popover } from "@contentful/f36-components";
import { CopyIcon, HorizontalDotsMenuIcon } from "assets/icons/icons";
import placeholder from "../../../assets/images/placeholder.png";
import Divider from "components/divider/Divider";
import { Input, Modal, message } from "antd";
import moment from "moment";
import { useNavigate } from "react-router-dom";

type GridCardProps = {
	appData: {
		_id: string;
		name: string;
		updatedAt: string;
		createdAt: string;
		isDeleted: boolean;
		lastViewed: string;
	};
	renameApp: (id: string) => Promise<void>;
	setSelectedAppName: React.Dispatch<React.SetStateAction<string>>;
	fetchAllApps: () => Promise<void>;
	deleteApp: (id: string) => Promise<void>;
	duplicateApp: (id: string) => Promise<void>;
};

const GridCard: React.FunctionComponent<GridCardProps> = ({
	appData,
	renameApp,
	setSelectedAppName,
	fetchAllApps,
	deleteApp,
	duplicateApp,
}) => {
	const [isPopOverOpen, setIsPopOverOpen] = useState<boolean>(false);
	const [isRenameModalOpen, setIsRenameModalOpen] = useState<boolean>(false);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
	const navigate = useNavigate();
	const [messageApi, contextHolder] = message.useMessage();
	const [isRenameAppLoading, setIsRenameAppLoading] = useState<boolean>(false);
	const [isDeleteAppLoading, setIsDeleteAppLoading] = useState<boolean>(false);

	const handleRename = async () => {
		setIsRenameAppLoading(true);
		await renameApp(appData._id);
		fetchAllApps();
		setIsRenameModalOpen(false);
		setIsRenameAppLoading(false);
	};

	const handleDelete = async () => {
		setIsDeleteAppLoading(true);
		await deleteApp(appData._id);
		fetchAllApps();
		setIsDeleteModalOpen(false);
		setIsDeleteAppLoading(false);
	};

	const handleDuplicate = async () => {
		await duplicateApp(appData._id);
		setIsPopOverOpen(false);
		fetchAllApps();
	};

	const RenameModalFooter = () => {
		return (
			<div className="renameFooter">
				<button onClick={() => setIsRenameModalOpen(false)} className="cancel">
					Cancel
				</button>
				<Button
					isLoading={isRenameAppLoading}
					isDisabled={isRenameAppLoading}
					onClick={handleRename}
					className="update"
				>
					Rename
				</Button>
			</div>
		);
	};

	const DeleteModalFooter = () => {
		return (
			<div className="renameFooter">
				<button onClick={() => setIsDeleteModalOpen(false)} className="cancel">
					Cancel
				</button>
				<Button
					onClick={handleDelete}
					isLoading={isDeleteAppLoading}
					isDisabled={isDeleteAppLoading}
					className="delete"
				>
					Delete
				</Button>
			</div>
		);
	};

	const copyLink = () => {
		const appLink = `${window.location.origin}/apps/${appData._id}`;
		navigator.clipboard.writeText(appLink);
		setIsPopOverOpen(false);
		messageApi.open({
			type: "info",
			content: "Link copied to clipboard",
			icon: <CopyIcon />,
		});
	};

	return (
		<div className="gridContainer">
			{contextHolder}
			<img src={placeholder} alt="" />
			<div className="cardDetails">
				<div className="leftDeets">
					<p className="appName">{appData.name}</p>
					<p className="lastViewed">
						Viewed {moment(appData.lastViewed).startOf("minute").fromNow()}
					</p>
				</div>
				<div className="rightDeets">
					<Popover
						isOpen={isPopOverOpen}
						id="gridPopover"
						onClose={() => setIsPopOverOpen(false)}
					>
						<Popover.Trigger>
							<button
								className={isPopOverOpen ? "openActive menu" : "menu"}
								onClick={() => setIsPopOverOpen(!isPopOverOpen)}
							>
								<HorizontalDotsMenuIcon />
							</button>
						</Popover.Trigger>
						<Popover.Content>
							<div className="dropWrapper">
								<button
									onClick={() => navigate(`/apps/${appData._id}`)}
									className="menuDrop"
								>
									Open
								</button>
								<button
									onClick={() => window.open(`/apps/${appData._id}`)}
									className="menuDrop"
								>
									Open in new tab
								</button>
								<Divider />
								<button onClick={handleDuplicate} className="menuDrop">
									Duplicate
								</button>
								<button onClick={copyLink} className="menuDrop">
									Copy link
								</button>
								<Divider />
								<button
									onClick={() => setIsRenameModalOpen(true)}
									className="menuDrop"
								>
									Rename
								</button>
								<button
									onClick={() => setIsDeleteModalOpen(true)}
									className="menuDrop"
								>
									Delete
								</button>
							</div>
						</Popover.Content>
					</Popover>
				</div>
			</div>
			<Modal
				title="Rename app"
				centered
				open={isRenameModalOpen}
				onOk={() => setIsRenameModalOpen(false)}
				onCancel={() => setIsRenameModalOpen(false)}
				className="renameModal"
				closable={false}
				footer={<RenameModalFooter />}
				width="300px"
			>
				<Input
					defaultValue={appData.name}
					onChange={(e) => setSelectedAppName(e.target.value)}
				/>
				<p>This change will be reflected for anyone viewing the project.</p>
			</Modal>
			<Modal
				title="Delete app"
				centered
				open={isDeleteModalOpen}
				onOk={() => setIsDeleteModalOpen(false)}
				onCancel={() => setIsDeleteModalOpen(false)}
				className="deleteModal"
				closable={false}
				width="300px"
				footer={<DeleteModalFooter />}
			>
				<p>Are you sure you want to delete this app?</p>
			</Modal>
		</div>
	);
};

export default GridCard;
