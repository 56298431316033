import {
  APICall,
  GroupCall,
} from "pages/protected/apiCollections/APICollections";
import React, { useEffect, useState } from "react";
import "./apiGroupCard.scss";
import { Collapse, Input, message, Modal, Spin } from "antd";
import ApiCallNameCard from "components/apiCallNameCard/ApiCallNameCard";
import { Button, Popover } from "@contentful/f36-components";
import { DeleteIcon, HorizontalDotsMenuIcon } from "assets/icons/icons";
import Divider from "components/divider/Divider";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useApiService } from "helpers/api-service";

type ApiGroupCardProps = {
  group: GroupCall;
  setSelectedGroup: React.Dispatch<React.SetStateAction<GroupCall>>;
  setSelectedApiCall: React.Dispatch<React.SetStateAction<APICall>>;
  selectedApiCall: APICall;
  resetApiCallFields: () => void;
  defaultApiCallObject: APICall;
  setCurrentPanelID: React.Dispatch<React.SetStateAction<string | string[]>>;
  currentPanelID: string | string[];
  refetchGroups: () => any;
  setNewGroupName: React.Dispatch<React.SetStateAction<string>>;
  runEffectOnAutoToggle: { value: boolean; isDelete: boolean };
  setRunEffectOnAutoToggle: React.Dispatch<
    React.SetStateAction<{
      value: boolean;
      isDelete: boolean;
    }>
  >;
  renameGroup: () => void;
  renameGroupText: string;
  setRenameGroupText: React.Dispatch<React.SetStateAction<string>>;
  setRenameGroupID: React.Dispatch<React.SetStateAction<string>>;
  resetResponse: () => void;
};

const ApiGroupCard: React.FunctionComponent<ApiGroupCardProps> = ({
  setSelectedGroup,
  resetApiCallFields,
  group,
  setSelectedApiCall,
  selectedApiCall,
  defaultApiCallObject,
  setCurrentPanelID,
  currentPanelID,
  refetchGroups,
  setNewGroupName,
  runEffectOnAutoToggle,
  setRunEffectOnAutoToggle,
  renameGroup,
  renameGroupText,
  setRenameGroupText,
  setRenameGroupID,
  resetResponse,
}) => {
  const { Panel } = Collapse;
  const [isMenuPopOverOpen, setIsMenuPopOverOpen] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [isRenameModalOpen, setIsRenameModalOpen] = useState<boolean>(false);
  const [showPopOverIcon, setShowPopOverIcon] = useState<boolean>(false);
  const [isCallsLoading, setIsCallsLoading] = useState<boolean>(false);
  const [isDeleteGroupLoading, setIsDeleteGroupLoading] =
    useState<boolean>(false);
  const [isRenameGroupLoading, setIsRenameGroupLoading] =
    useState<boolean>(false);
  const client = useApiService();
  const [messageApi, contextHolder] = message.useMessage();

  const getCallsFn = async () => {
    const response = await client.get(`/apigroups/${group._id}/api-calls`);
    return response.data;
  };

  const deleteGroup = async () => {
    const response = await client.delete(`/apigroups/${group._id}`);
    return response.data;
  };

  const [apiCalls, setApiCalls] = useState<APICall[]>([]);

  const addCall = () => {
    resetApiCallFields();
    resetResponse();
    setSelectedGroup({
      ...group,
      apiCalls: [{ ...defaultApiCallObject, apiCollection: group._id }],
    });
    setSelectedApiCall({ ...defaultApiCallObject, apiCollection: group._id });
  };

  const {
    data: callsList,
    status: callListStatus,
    refetch: refetchCalls,
  } = useQuery({
    queryKey: ["callsLists"],
    queryFn: getCallsFn,
    onSuccess(calls) {
      calls.map((api: APICall) => {
        if (api._id === selectedApiCall._id) {
          api.class = "callCont selectedCall";
          api.showMenuIcon = true;
        } else {
          api.class = "callCont";
          api.showMenuIcon = false;
        }
      });
      setIsCallsLoading(false);
      setApiCalls(calls);
    },
    onError(err) {
      console.error(err);
    },
    enabled: false,
  });

  const groupPopOver = () => {
    return (
      <Popover
        isOpen={isMenuPopOverOpen}
        id="gridPopover"
        onClose={() => setIsMenuPopOverOpen(false)}
      >
        <Popover.Trigger>
          <button
            className={isMenuPopOverOpen ? "actv" : ""}
            onClick={(event) => {
              event.stopPropagation();
              setIsMenuPopOverOpen(!isMenuPopOverOpen);
            }}
          >
            {showPopOverIcon && <HorizontalDotsMenuIcon />}
          </button>
        </Popover.Trigger>
        <Popover.Content>
          <div className="dropWrapper">
            <button
              onClick={(event) => {
                event.stopPropagation();
                addCall();
                setIsMenuPopOverOpen(false);
              }}
              className="menuDrop"
            >
              Add call
            </button>
            <button
              onClick={(event) => {
                event.stopPropagation();
              }}
              className="menuDrop"
            >
              Duplicate
            </button>
            <Divider />
            <button
              onClick={(event) => {
                event.stopPropagation();
                setIsRenameModalOpen(true);
              }}
              className="menuDrop"
            >
              Rename
            </button>
            <button
              onClick={(event) => {
                event.stopPropagation();
                setIsDeleteModalOpen(true);
              }}
              className="menuDrop"
            >
              Delete
            </button>
          </div>
        </Popover.Content>
      </Popover>
    );
  };

  const DeleteModalFooter = () => {
    return (
      <div className="renameFooter">
        <button onClick={() => setIsDeleteModalOpen(false)} className="cancel">
          Cancel
        </button>
        <Button
          isDisabled={isDeleteGroupLoading}
          isLoading={isDeleteGroupLoading}
          className="delete"
          onClick={() => {
            setIsDeleteGroupLoading(true);
            deleteGroup().then(() => {
              setIsDeleteModalOpen(false);
              setIsDeleteGroupLoading(false);
              refetchGroups();
              setSelectedGroup({
                _id: "",
                name: "",
                apiCalls: [],
              });
              setNewGroupName("");
              messageApi.open({
                type: "success",
                content: "Group deleted",
                icon: <DeleteIcon />,
              });
            });
          }}
        >
          Delete
        </Button>
      </div>
    );
  };

  const RenameModalFooter = () => {
    return (
      <div className="renameFooter">
        <button onClick={() => setIsRenameModalOpen(false)} className="cancel">
          Cancel
        </button>
        <Button
          isLoading={isRenameGroupLoading}
          isDisabled={isRenameGroupLoading}
          onClick={async () => {
            setIsRenameGroupLoading(true);
            await renameGroup();
            setIsRenameModalOpen(false);
            setIsRenameGroupLoading(false);
          }}
          className="update"
        >
          Rename
        </Button>
      </div>
    );
  };

  const setFirstApiCallActive = (calls: APICall[]) => {
    if (calls.length > 0) {
      calls[0].class = "callCont selectedCall";
      calls[0].showMenuIcon = true;
      const firstID = calls[0]._id;
      const otherCalls = calls.filter((api: APICall) => api._id !== firstID);
      otherCalls.map((api: APICall) => {
        api.class = "callCont";
        api.showMenuIcon = false;
      });
    }
  };

  useEffect(() => {
    if (currentPanelID && runEffectOnAutoToggle.value) {
      setIsCallsLoading(true);
      getCallsFn().then((calls) => {
        calls.map((api: APICall, idx: number, array: APICall[]) => {
          api.class = "callCont";
          api.showMenuIcon = false;
          if (
            api._id === selectedApiCall._id &&
            !runEffectOnAutoToggle.isDelete
          ) {
            api.class = "callCont selectedCall";
            api.showMenuIcon = true;
          }
        });
        setIsCallsLoading(false);
        setApiCalls(calls);
      });
      setRunEffectOnAutoToggle({ value: false, isDelete: false });
    }
  }, [currentPanelID]);

  useEffect(() => {
    setRenameGroupText(group.name);
    setRenameGroupID(group._id);
  }, [isRenameModalOpen]);

  return (
    <>
      {contextHolder}
      <div
        className="groupCard"
        onMouseOver={() => setShowPopOverIcon(true)}
        onMouseLeave={() => setShowPopOverIcon(false)}
      >
        <Collapse
          activeKey={currentPanelID}
          accordion
          onChange={(panelID) => {
            setCurrentPanelID(panelID);
            resetResponse();
            if (panelID) {
              setIsCallsLoading(true);
              getCallsFn().then((calls) => {
                calls.map((api: APICall) => {
                  api.class = "callCont";
                  api.showMenuIcon = false;
                });
                setIsCallsLoading(false);
                setApiCalls(calls);
                setFirstApiCallActive(calls);
                setSelectedGroup({ ...group, apiCalls: calls });
                setSelectedApiCall(calls[0]);
              });
            }
          }}
        >
          <Panel header={group.name} key={group._id} extra={groupPopOver()}>
            {isCallsLoading ? (
              <div className="callSpinner">
                <Spin />
              </div>
            ) : (
              <>
                {apiCalls.length > 0 ? (
                  <div className="callList">
                    {apiCalls.map((api) => (
                      <ApiCallNameCard
                        key={api._id}
                        apiCallList={apiCalls}
                        setSelectedApiCall={setSelectedApiCall}
                        apiCallObject={api}
                        setSelectedGroup={setSelectedGroup}
                        group={{ ...group, apiCalls: apiCalls }}
                        refetchCalls={refetchCalls}
                        resetResponse={resetResponse}
                      />
                    ))}
                  </div>
                ) : (
                  <div className="empCall">
                    <p>
                      This group is empty <br />{" "}
                      <span onClick={addCall} className="add">
                        Add a call
                      </span>{" "}
                      to get started
                    </p>
                  </div>
                )}
              </>
            )}
          </Panel>
        </Collapse>
        <Modal
          title="Delete group"
          centered
          open={isDeleteModalOpen}
          onOk={() => setIsDeleteModalOpen(false)}
          onCancel={() => setIsDeleteModalOpen(false)}
          className="deleteModal"
          closable={false}
          width="350px"
          footer={<DeleteModalFooter />}
        >
          <p>
            Are you sure you want to delete '{group.name}' from your API
            collection?
          </p>
        </Modal>
        <Modal
          title="Rename group"
          centered
          open={isRenameModalOpen}
          onOk={() => setIsRenameModalOpen(false)}
          onCancel={() => setIsRenameModalOpen(false)}
          className="renameModal"
          closable={false}
          footer={<RenameModalFooter />}
          width="320px"
        >
          <p>Group name</p>
          <Input
            onChange={(e) => setRenameGroupText(e.target.value)}
            value={renameGroupText}
            placeholder="Enter group name"
          />
        </Modal>
      </div>
    </>
  );
};

export default ApiGroupCard;
