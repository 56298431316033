import {
	APICollectionsLogo,
	AppsLogo,
	TemplatesLogo,
} from "assets/icons/icons";
import React from "react";
import Logo from "../../assets/images/logo.svg";
import "./sidebar.scss";
import { useLocation, useNavigate } from "react-router-dom";
import {
	API_COLLECTIONS_PAGE_ROUTE,
	APPS_PAGE_ROUTE,
	TEMPLATES_PAGE_ROUTE,
} from "utils/routes";

type SidebarProps = {};

const Sidebar: React.FunctionComponent<SidebarProps> = () => {
	const location = useLocation();
	const navigate = useNavigate();

	return (
		<div className="sidebar">
			<div className="logo">
				<img src={Logo} alt="uno logo" />
				<h1>Uno</h1>
			</div>
			<div className="sideNav">
				<button
					className={
						location.pathname === APPS_PAGE_ROUTE
							? "sideLink active"
							: "sideLink"
					}
					onClick={() => navigate(APPS_PAGE_ROUTE)}
				>
					<AppsLogo />
					<span>Apps</span>
				</button>
				<button
					className={
						location.pathname === API_COLLECTIONS_PAGE_ROUTE
							? "sideLink active"
							: "sideLink"
					}
					onClick={() => navigate(API_COLLECTIONS_PAGE_ROUTE)}
				>
					<APICollectionsLogo />
					<span>API Collections</span>
				</button>
				<button
					className={
						location.pathname === TEMPLATES_PAGE_ROUTE
							? "sideLink active"
							: "sideLink"
					}
					onClick={() => navigate(TEMPLATES_PAGE_ROUTE)}
				>
					<TemplatesLogo />
					<span>Templates</span>
				</button>
			</div>
		</div>
	);
};

export default Sidebar;
