import Sidebar from "../../../components/sidebar/Sidebar";
import Topbar from "../../../components/topbar/Topbar";
import React, { useEffect, useState } from "react";
import { Button, Flex, Popover } from "@contentful/f36-components";
import { PlusIcon } from "@contentful/f36-icons";
import {
  ArrowDownIcon,
  CloseModalIcon,
  CopyIcon,
  GridIcon,
  HorizontalDotsMenuIcon,
  ListIcon,
  SearchIcon,
  SuccessCheckIcon,
} from "../../../assets/icons/icons";
import "./appsPage.scss";
import {
  ALPHABETICAL_SORT,
  DATE_CREATED_SORT,
  GRID_LAYOUT,
  LAST_VIEWED_SORT,
  LIST_LAYOUT,
  NEWEST_ORDER_SORT,
} from "../../../utils/constants";
import SortFilter from "../../../components/filters/SortFilter";
import { Input, Pagination, Segmented, Skeleton, Spin } from "antd";
import GridCard from "../../../components/layoutView/gridLayout/GridCard";
import { Table, Modal, message } from "antd";
import type { ColumnsType, TableProps } from "antd/es/table";
import Divider from "../../../components/divider/Divider";
import listPlaceholder from "../../../assets/images/listholder.png";
import TemplateCard from "components/template/TemplateCard";
import { useNavigate } from "react-router-dom";
import { useApiService } from "helpers/api-service";
import moment from "moment";
import type { PaginationProps } from "antd";
import EmptyAppsPage from "components/emptyAppsPageState/EmptyAppsPage";

type AppsPageProps = {};

export type AppDataProps = {
  _id: string;
  name: string;
  updatedAt: string;
  createdAt: string;
  isDeleted: boolean;
  lastViewed: string;
};

type ListPopOverProps = {
  appName: string;
  allApps: AppDataProps;
};

type AllAppsDataProps = {
  limit: number;
  page: number;
  results: AppDataProps[];
  totalPages: number;
  totalResults: number;
};

const AppsPage: React.FunctionComponent<AppsPageProps> = () => {
  const [isSortOpen, setIsSortOpen] = useState(false);
  const [appsSort, setAppsSort] = useState<string>(ALPHABETICAL_SORT);
  const [orderSort, setOrderSort] = useState<number>(NEWEST_ORDER_SORT);
  const [layoutView, setLayoutView] = useState(GRID_LAYOUT);
  const [isCreateNewModalOpen, setIsCreateNewModalOpen] =
    useState<boolean>(false);
  const [allApps, setAllApps] = useState<AppDataProps[]>([]);
  const [allAppsData, setAllAppsData] = useState<AllAppsDataProps>();
  const [allTemplates, setAllTemplates] = useState<AppDataProps[]>([]);
  const navigate = useNavigate();
  const [isRenameModalOpen, setIsRenameModalOpen] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [selectedAppName, setSelectedAppName] = useState<string>("");
  const [selectedAppID, setSelectedAppID] = useState<string>("");
  const client = useApiService();
  const [appName, setAppName] = useState<string>("");
  const [messageApi, contextHolder] = message.useMessage();
  const [isRenameAppLoading, setIsRenameAppLoading] = useState<boolean>(false);
  const [isDeleteAppLoading, setIsDeleteAppLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isTableLoading, setIsTableLoading] = useState<boolean>(false);
  const [isAppsPageLoading, setIsAppsPageLoading] = useState<boolean>(true);
  const [searchFetchString, setSearchFetchString] = useState<string>("");
  const [sortFetchString, setSortFetchString] = useState<string>("name");
  const [sortOrderFetchNumber, setSortOrderFetchNumber] = useState<number>(1);

  const RenameModalFooter = () => {
    return (
      <div className="renameFooter">
        <button onClick={() => setIsRenameModalOpen(false)} className="cancel">
          Cancel
        </button>
        <Button
          isLoading={isRenameAppLoading}
          isDisabled={isRenameAppLoading}
          onClick={() => renameApp(selectedAppID)}
          className="update"
        >
          Rename
        </Button>
      </div>
    );
  };

  const DeleteModalFooter = () => {
    return (
      <div className="renameFooter">
        <button onClick={() => setIsDeleteModalOpen(false)} className="cancel">
          Cancel
        </button>
        <Button
          isLoading={isDeleteAppLoading}
          isDisabled={isDeleteAppLoading}
          onClick={() => deleteApp(selectedAppID)}
          className="delete"
        >
          Delete
        </Button>
      </div>
    );
  };

  const fetchAllApps = async () => {
    const response = await client.get(
      `/apps?sortBy[${sortFetchString}]=${sortOrderFetchNumber}&page=${currentPage}${
        searchFetchString && `&search=${searchFetchString}`
      }`
    );
    setAllApps(response.data.results);
    setAllAppsData(response.data);
    setIsAppsPageLoading(false);
  };

  const searchApps = async (searchValue: string) => {
    setIsAppsPageLoading(true);
    setSearchFetchString(searchValue);
    const response = await client.get(
      `/apps?sortBy[${sortFetchString}]=${sortOrderFetchNumber}&page=${1}${
        searchValue && `&search=${searchValue}`
      }`
    );
    setAllApps(response.data.results);
    setAllAppsData(response.data);
    if (!searchValue) {
      setCurrentPage(1);
    }
    setIsAppsPageLoading(false);
  };

  const handleSort = async (sortOrder: number, sortBy: string) => {
    setIsAppsPageLoading(true);
    setSortOrderFetchNumber(sortOrder);
    switch (sortBy) {
      case ALPHABETICAL_SORT: {
        setSortFetchString("name");
        const response = await client.get(
          `/apps?sortBy[name]=${sortOrder}&page=${currentPage}${
            searchFetchString && `&search=${searchFetchString}`
          }`
        );
        setAllApps(response.data.results);
        setIsAppsPageLoading(false);
        break;
      }
      case LAST_VIEWED_SORT: {
        setSortFetchString("lastViewed");
        const response = await client.get(
          `/apps?sortBy[lastViewed]=${sortOrder}&page=${currentPage}${
            searchFetchString && `&search=${searchFetchString}`
          }`
        );
        setAllApps(response.data.results);
        setIsAppsPageLoading(false);
        break;
      }
      case DATE_CREATED_SORT: {
        setSortFetchString("createdAt");
        const response = await client.get(
          `/apps?sortBy[createdAt]=${sortOrder}&page=${currentPage}${
            searchFetchString && `&search=${searchFetchString}`
          }`
        );
        setAllApps(response.data.results);
        setIsAppsPageLoading(false);
        break;
      }
      default: {
        const response = await client.get(
          `/apps?sortBy[alphabetical]=${sortOrder}&page=${currentPage}${
            searchFetchString && `&search=${searchFetchString}`
          }`
        );
        setAllApps(response.data.results);
        setIsAppsPageLoading(false);
        break;
      }
    }
  };

  const onTablePageChange: TableProps<AppDataProps>["onChange"] = async (
    pagination
  ) => {
    setIsTableLoading(true);
    setCurrentPage(pagination.current);
    const response = await client.get(
      `/apps?sortBy[${sortFetchString}]=${sortOrderFetchNumber}&page=${
        pagination.current
      }${searchFetchString && `&search=${searchFetchString}`}`
    );
    setAllApps(response.data.results);
    setIsTableLoading(false);
  };

  const onGridPageChange: PaginationProps["onChange"] = async (page) => {
    setIsAppsPageLoading(true);
    setCurrentPage(page);
    const response = await client.get(
      `/apps?sortBy[${sortFetchString}]=${sortOrderFetchNumber}&page=${page}${
        searchFetchString && `&search=${searchFetchString}`
      }`
    );
    setAllApps(response.data.results);
    setIsAppsPageLoading(false);
  };

  const fetchAllTemplates = async () => {
    const response = await client.get(`/templates`);
    setAllTemplates(response.data.results);
  };

  const createNewApp = async (newApp: { name: string; template?: string }) => {
    await client.post(`/apps`, newApp);
    setAppName("");
    fetchAllApps();
    messageApi.open({
      type: "success",
      content: "App created successfully",
      icon: <SuccessCheckIcon />,
    });
  };

  const renameApp = async (id: string) => {
    setIsRenameAppLoading(true);
    await client.patch(`/apps/${id}`, {
      name: selectedAppName,
    });
    fetchAllApps();
    setIsRenameModalOpen(false);
    setIsRenameAppLoading(false);
  };

  const deleteApp = async (id: string) => {
    setIsDeleteAppLoading(true);
    await client.delete(`/apps/${id}`);
    fetchAllApps();
    setIsDeleteModalOpen(false);
    setIsDeleteAppLoading(false);
  };

  const duplicateApp = async (id: string) => {
    await client.post(`/apps/${id}/duplicate`, { appId: id });
    fetchAllApps();
    messageApi.open({
      type: "success",
      content: "App duplicated successfully",
      icon: <SuccessCheckIcon />,
    });
  };

  useEffect(() => {
    fetchAllApps();
    handleSort(NEWEST_ORDER_SORT, ALPHABETICAL_SORT);
    fetchAllTemplates();
  }, []);

  const handleAppNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAppName(e.target.value);
  };

  const columns: ColumnsType<AppDataProps> = [
    {
      title: "App name",
      dataIndex: "name",
      width: "40%",
      render: (appName, allApps) => (
        <div className="listAppName">
          <img src={listPlaceholder} alt="" />
          <span className="appnam">{appName}</span>
        </div>
      ),
    },
    {
      title: (
        <div className="viewHead">
          <span>Last viewed</span> <ArrowDownIcon />
        </div>
      ),
      dataIndex: "lastViewed",
      // sorter: (a, b) => a.lastViewed - b.lastViewed,
      width: "30%",
      render: (view) => (
        <span className="faint">
          Viewed {moment(view).startOf("minute").fromNow()}
        </span>
      ),
    },
    {
      title: "Created",
      dataIndex: "createdAt",
      width: "20%",
      render: (date) => (
        <span className="faint">
          {moment(date).startOf("minute").fromNow()}
        </span>
      ),
    },
    {
      title: "Actions",
      width: "10%",
      dataIndex: "name",
      render: (appName, allApps) => (
        <ListPopover appName={appName} allApps={allApps} />
      ),
    },
  ];

  const handleRenameModal = (appName: string, id: string) => {
    setSelectedAppName(appName);
    setIsRenameModalOpen(true);
    setSelectedAppID(id);
  };

  const handleDeleteModal = (id: string) => {
    setIsDeleteModalOpen(true);
    setSelectedAppID(id);
  };

  const ListPopover = ({ appName, allApps }: ListPopOverProps) => {
    const [isListPopoverOpen, setIsListPopoverOpen] = useState(false);

    const copyLink = () => {
      const appLink = `${window.location.origin}/apps/${allApps._id}`;
      navigator.clipboard.writeText(appLink);
      setIsListPopoverOpen(false);
      messageApi.open({
        type: "info",
        content: "Link copied to clipboard",
        icon: <CopyIcon />,
      });
    };

    const handleDuplicate = () => {
      duplicateApp(allApps._id);
      setIsListPopoverOpen(false);
    };

    return (
      <Popover
        isOpen={isListPopoverOpen}
        id="gridPopover"
        onClose={() => setIsListPopoverOpen(false)}
      >
        <Popover.Trigger>
          <div className="flex-end">
            <button
              className={isListPopoverOpen ? "openActive menu" : "menu"}
              onClick={() => setIsListPopoverOpen(!isListPopoverOpen)}
            >
              <HorizontalDotsMenuIcon />
            </button>
          </div>
        </Popover.Trigger>
        <Popover.Content>
          <div className="dropWrapper">
            <button
              onClick={() => navigate(`/apps/${allApps._id}`)}
              className="menuDrop"
            >
              Open
            </button>
            <button
              onClick={() => window.open(`/apps/${allApps._id}`)}
              className="menuDrop"
            >
              Open in new tab
            </button>
            <Divider />
            <button onClick={handleDuplicate} className="menuDrop">
              Duplicate
            </button>
            <button onClick={copyLink} className="menuDrop">
              Copy link
            </button>
            <Divider />
            <button
              onClick={() => handleRenameModal(appName, allApps._id)}
              className="menuDrop"
            >
              Rename
            </button>
            <button
              onClick={() => handleDeleteModal(allApps._id)}
              className="menuDrop"
            >
              Delete
            </button>
          </div>
        </Popover.Content>
      </Popover>
    );
  };

  return (
    <Flex>
      <Sidebar />
      <main>
        {contextHolder}
        <Topbar />
        <div className="padLayout">
          <div className="topLayout">
            <h3 className="pageName">Apps</h3>
            <div className="searchNCreate">
              <Input
                prefix={<SearchIcon />}
                onChange={(e) => searchApps(e.target.value)}
                placeholder="Search"
                allowClear
              />
              <Button
                className="createNew"
                startIcon={<PlusIcon />}
                variant="primary"
                onClick={() => setIsCreateNewModalOpen(true)}
              >
                Create new
              </Button>
            </div>
          </div>
          <div className="filtersToggle">
            <SortFilter
              isSortOpen={isSortOpen}
              setIsSortOpen={setIsSortOpen}
              appsSort={appsSort}
              setAppsSort={setAppsSort}
              orderSort={orderSort}
              setOrderSort={setOrderSort}
              handleSort={handleSort}
            />
            <div className="sortToggle">
              <div className="toggle">
                <Segmented
                  options={[
                    {
                      value: GRID_LAYOUT,
                      icon: <GridIcon />,
                    },
                    {
                      value: LIST_LAYOUT,
                      icon: <ListIcon />,
                    },
                  ]}
                  onChange={(prevValue) =>
                    prevValue !== GRID_LAYOUT
                      ? setLayoutView(LIST_LAYOUT)
                      : setLayoutView(GRID_LAYOUT)
                  }
                />
              </div>
            </div>
          </div>
          <div className="mainContent">
            {isAppsPageLoading ? (
              <div className="appsSpinBox">
                <Spin />
              </div>
            ) : (
              <>
                {allApps.length > 0 ? (
                  <>
                    {layoutView === GRID_LAYOUT ? (
                      <>
                        <div className="gridApps">
                          {allApps.map((appDetails) => (
                            <GridCard
                              key={appDetails._id}
                              appData={appDetails}
                              renameApp={renameApp}
                              deleteApp={deleteApp}
                              setSelectedAppName={setSelectedAppName}
                              fetchAllApps={fetchAllApps}
                              duplicateApp={duplicateApp}
                            />
                          ))}
                        </div>
                        {allApps.length > 0 && (
                          <Pagination
                            total={allAppsData?.totalResults}
                            current={currentPage}
                            onChange={onGridPageChange}
                          />
                        )}
                      </>
                    ) : (
                      <div className="listTable">
                        <Table
                          columns={columns}
                          dataSource={allApps}
                          onChange={onTablePageChange}
                          rowKey="_id"
                          pagination={{
                            total: allAppsData?.totalResults,
                            current: currentPage,
                          }}
                          loading={isTableLoading}
                        />
                      </div>
                    )}
                  </>
                ) : (
                  <div className="centEmp">
                    <EmptyAppsPage
                      handleClick={() => setIsCreateNewModalOpen(true)}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </main>
      <Modal
        title="Create new"
        centered
        open={isCreateNewModalOpen}
        onOk={() => setIsCreateNewModalOpen(false)}
        onCancel={() => setIsCreateNewModalOpen(false)}
        closeIcon={<CloseModalIcon />}
        className="createNew"
        width={"80%"}
        footer={null}
      >
        <div className="createNewBody">
          <div className="formField">
            <label htmlFor="appName">App name: *</label>
            <Input
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleAppNameChange(e)
              }
              value={appName}
            />
          </div>
          <div className="templatesContainer">
            <TemplateCard
              isBlankApp
              createNewApp={createNewApp}
              setIsCreateNewModalOpen={setIsCreateNewModalOpen}
              appName={appName}
            />
          </div>
          <div className="browseAllTemplates">
            <p className="template">Templates</p>
            <button onClick={() => navigate("/templates")} className="browse">
              Browse all
            </button>
          </div>
          <div className="templatesContainer">
            {allTemplates.map((appDetails) => (
              <TemplateCard
                key={appDetails._id}
                appData={appDetails}
                createNewApp={createNewApp}
                setIsCreateNewModalOpen={setIsCreateNewModalOpen}
                appName={appName}
              />
            ))}
          </div>
        </div>
      </Modal>
      <Modal
        title="Rename app"
        centered
        open={isRenameModalOpen}
        onOk={() => setIsRenameModalOpen(false)}
        onCancel={() => setIsRenameModalOpen(false)}
        className="renameModal"
        closable={false}
        footer={<RenameModalFooter />}
        width="300px"
      >
        <Input
          value={selectedAppName}
          onChange={(e) => setSelectedAppName(e.target.value)}
        />
        <p>This change will be reflected for anyone viewing the project.</p>
      </Modal>
      <Modal
        title="Delete app"
        centered
        open={isDeleteModalOpen}
        onOk={() => setIsDeleteModalOpen(false)}
        onCancel={() => setIsDeleteModalOpen(false)}
        className="deleteModal"
        closable={false}
        width="300px"
        footer={<DeleteModalFooter />}
      >
        <p>Are you sure you want to delete this app?</p>
      </Modal>
    </Flex>
  );
};

export default AppsPage;
