import APICollections from "pages/protected/apiCollections/APICollections";
import AppsPage from "pages/protected/appsPage/AppsPage";
import Templates from "pages/protected/templates/Templates";
import React, { lazy } from "react";
import { RouteProps } from "react-router";
import { Route, Routes, Navigate } from "react-router-dom";

import ProtectedRoute from "../components/route/protected-route";
import GeneralRoute from "../components/route/general-route";
import {
  SIGNUP_ROUTE,
  SIGNUP_VERIFICATION_ROUTE,
  SIGNUP_VERIFICATION_EMAIL_ROUTE,
  LOGIN_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  APPS_PAGE_ROUTE,
  API_COLLECTIONS_PAGE_ROUTE,
  TEMPLATES_PAGE_ROUTE,
  CHECKMAIL_ROUTE,
  RESET_PASSWORD_ROUTE,
  RESET_SUCCESS_ROUTE,
} from "../utils/routes";
import { useUserService } from "../helpers/user-service";

const Signup = lazy(() => import("../pages/general/signup/Signup"));
const SignupVerify = lazy(
  () => import("../pages/general/verifyMail/verify-mail")
);
const MailVerification = lazy(
  () => import("../pages/general/mailVerification/mail-verification")
);
const Login = lazy(() => import("../pages/general/login/Login"));
const ForgotPassword = lazy(
  () => import("../pages/general/forgot-password/forgot-password")
);
const CheckMail = lazy(() => import("../pages/general/checkMail/checkMail"));
const ResetPassword = lazy(
  () => import("../pages/general/reset-password/reset-password")
);
const ResetSuccess = lazy(
  () => import("../pages/general/reset-password/reset-password-success")
);
const NotFound = lazy(() => import("../pages/general/not-found/not-found"));

const RouteContainer: React.FunctionComponent<RouteProps> = () => {
  const { user } = useUserService();
  return (
    <Routes>
      <Route element={<GeneralRoute />}>
        <Route path={LOGIN_ROUTE} element={<Login />} />
      </Route>
      {/* <Route path={LOGIN_ROUTE} element={<Login />} /> */}
      <Route path={SIGNUP_ROUTE} element={<Signup />} />
      <Route path={SIGNUP_VERIFICATION_ROUTE} element={<SignupVerify />} />
      <Route
        path={SIGNUP_VERIFICATION_EMAIL_ROUTE}
        element={<MailVerification />}
      />
      <Route path={FORGOT_PASSWORD_ROUTE} element={<ForgotPassword />} />
      <Route path={CHECKMAIL_ROUTE} element={<CheckMail />} />
      <Route path={RESET_PASSWORD_ROUTE} element={<ResetPassword />} />
      <Route path={RESET_SUCCESS_ROUTE} element={<ResetSuccess />} />
      <Route element={<ProtectedRoute />}>
        <Route path={APPS_PAGE_ROUTE} element={<AppsPage />} />
        <Route path={API_COLLECTIONS_PAGE_ROUTE} element={<APICollections />} />
        <Route path={TEMPLATES_PAGE_ROUTE} element={<Templates />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default RouteContainer;
