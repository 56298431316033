import { ChevronDownIcon, ChevronUpIcon } from "@contentful/f36-icons";
import React, { useState } from "react";

type TemplateFeatureCardProps = {
	featInfo: {
		title: string;
		text: string;
	};
};

const TemplateFeatureCard: React.FunctionComponent<
	TemplateFeatureCardProps
> = ({ featInfo }) => {
	const [isFeatOpen, setIsFeatOpen] = useState<boolean>(false);

	return (
		<div className="feat">
			<div
				className={isFeatOpen ? "featTitleBar open" : "featTitleBar"}
				onClick={() => setIsFeatOpen((prev) => !prev)}
			>
				<h3>{featInfo?.title}</h3>
				{isFeatOpen ? (
					<ChevronUpIcon size="medium" variant="muted" />
				) : (
					<ChevronDownIcon size="medium" variant="muted" />
				)}
			</div>
			{isFeatOpen && (
				<div className="featText">
					<p>{featInfo?.text}</p>
				</div>
			)}
		</div>
	);
};

export default TemplateFeatureCard;
