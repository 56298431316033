import { EmptyGroupIcon } from "assets/icons/icons";
import * as React from "react";

type EmptyGroupsProps = {
  handleClick: () => void;
};

const EmptyGroups: React.FunctionComponent<EmptyGroupsProps> = ({
  handleClick,
}) => {
  return (
    <div className="emptyWrapper noBgWrapper">
      <EmptyGroupIcon />
      <p className="no addMg">You don’t have any groups</p>
      <p className="sub">
        Groups let you manage related calls, making them <br /> easier to access
        and run.
      </p>
      <button className="noBg" onClick={handleClick}>
        Create group
      </button>
    </div>
  );
};

export default EmptyGroups;
