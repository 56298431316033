import React, { useState } from "react";
import { Popover } from "@contentful/f36-popover";
import "./topbar.scss";
import {
  ArrowDownIcon,
  HelpCenterIcon,
  LogoutIcon,
  SettingIcon,
  SingleProfileIcon,
  UserProfileIcon,
} from "../../assets/icons/icons";
import Divider from "../../components/divider/Divider";

import { Modal } from "antd";

import { useUserService } from "../../helpers/user-service";

type TopbarProps = {};

const Topbar: React.FunctionComponent<TopbarProps> = () => {
  // const { clearUserObj, user } = useUserService();
  const [isOpen, setIsOpen] = useState(false);
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState<boolean>(false);
  const userService = useUserService();
  const userDetails = userService?.user;

  const LogoutModalFooter = () => {
    return (
      <div className="renameFooter">
        <button onClick={() => setIsLogoutModalOpen(false)} className="update">
          Cancel
        </button>
        <button onClick={() => userService?.clearUserObj()} className="cancel">
          Yes
        </button>
      </div>
    );
  };

  return (
    <div className="topbar">
      <div className="dropdownMenu">
        <Popover id="popMenu" isOpen={isOpen} onClose={() => setIsOpen(false)}>
          <Popover.Trigger>
            <button
              className={isOpen ? "dropDwn isActive" : "dropDwn"}
              onClick={() => setIsOpen(!isOpen)}
            >
              <UserProfileIcon />
              <span className="mg10"></span>
              <ArrowDownIcon />
            </button>
          </Popover.Trigger>
          <Popover.Content>
            <div className="userDeets">
              <UserProfileIcon />
              <div className="nameEmail">
                <p className="name">
                  {userDetails?.firstname} {userDetails?.lastname}
                </p>
                <p className="email">{userDetails?.email}</p>
              </div>
            </div>
            <div className="btnLinks">
              <button className="drpdwnLink">
                <SingleProfileIcon />
                <span>Profile</span>
              </button>
              <button className="drpdwnLink">
                <SettingIcon />
                <span>Settings</span>
              </button>
              <button className="drpdwnLink">
                <HelpCenterIcon />
                <span>Help Center</span>
              </button>
              <Divider />
              <button
                className="drpdwnLink logout"
                onClick={() => setIsLogoutModalOpen(true)}
              >
                <LogoutIcon />
                <span>Logout</span>
              </button>
            </div>
          </Popover.Content>
        </Popover>
      </div>
      <Modal
        title="Log out"
        centered
        open={isLogoutModalOpen}
        onOk={() => setIsLogoutModalOpen(false)}
        onCancel={() => setIsLogoutModalOpen(false)}
        className="renameModal"
        closable={false}
        footer={<LogoutModalFooter />}
        width="300px"
      >
        <p>Are you sure you want to log out?</p>
      </Modal>
    </div>
  );
};

export default Topbar;
